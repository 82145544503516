@import "variables.pcss";

.editMileages {
  align-items: center;

  &-label {
    @mixin label-font;
    position: relative;
    height: 100%;
    float: left;
    font-size: 12px;

    &-text{
      position: absolute;
      transform: translateY(-100%);
    }
  }

  &-button{
    width: 100%;
    font-size: 14px;
    justify-content: space-between;

    &-icon{
      width: 20px;
      height: 20px;
    }
  }
}

.editIcon {
  height: 20px;
  width: 20px;
}

.link {
  cursor: pointer;
  border: none;
  background: transparent;
  padding: 0;
  margin: 0;
  text-align: left;
  text-decoration: underline;
}
