@import 'variables.pcss';

.selectButton{
  user-select: none;
  width: fit-content;
  max-width: 100%;

  &-open{
    color: var(--message-information-color);
    width: 20px;
    height: 20px;
    transform: scale(-1, 1);
  
    &:hover{
      color: #5eabfd;
    }
  }
  
  &-cancel{
    &:hover{
      color: #576c7f;
    }
  }

  &-text{
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  &-disabled{
    pointer-events: none !important;
    cursor: not-allowed;
  }

  &-notAllowed{
    cursor: not-allowed;
  }
}

.modal{
  height: 90vh;

  &-mobile{
    height: 100vh;
  }
}

.listRow{
  padding: 5px 8px 5px 5px;

  &-mobile {
    padding: 5px;
  }
}

.noEntities{
  &-buttons {
      display: flex;
      flex-direction: row;
      gap: 8px;
      width: max-content;
      margin: 0 auto 16px auto;

      &-inner {
            display: flex;
            flex-direction: row;
            gap: 8px;
      }
  }
  &-heading{
      font-size: 32px;
      margin-bottom: 32px;

      &-mobile{
        font-size: 16px;
      }
  }
  &-searchIcon{
      display: block;
      margin-bottom: 32px;
  }
}

.emptyContent{
  display: flex;
  flex-flow: column;
  gap: var(--spacing-m);
  text-align: center;
  padding: 32px;
  font-size: 16px;
  &-icon{
    display: flex;
    justify-content: flex-end;
  }
  &-text{
    white-space: pre-wrap;
  }
}