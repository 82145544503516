@import 'variables.pcss';

.layout {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: var(--grey-9-color);
  @media print {
    display: block;
  }

  &-grid{
    display: grid;
    grid-template-areas: 'sidebar header ' 'sidebar extracontent' 'sidebar content';
    grid-template-rows: 66px min-content 1fr;
    height: 100vh;

    @media (min-width: 1401px) {
      grid-template-columns: 230px;
    }
    @media (max-width: 1400px) {
      grid-template-columns: 65px;
    }
    @media (--breakpoint-mobile) {
      grid-template-columns: 0px 1fr;
    }

    &-min{
      grid-template-columns: 65px;

      @media (--breakpoint-mobile) {
        grid-template-columns: 0px 1fr;
      }
    }
  }

  &-page {
    display: flex;
    flex: 1;
    @media print {
      display: block;
    }
  }

  &-content {
    position: relative;
    display: flex;
    flex: 1;
    flex-direction: column;
    grid-area: content;
    background-color: var(--grey-9-color);
    width: 100%;
    overflow: auto;

    @media print {
      display: block;
    }
  }
}

.layoutExtraContent{
  background-color: var(--grey-9-color);
  display: flex;
  flex: 1;
  flex-direction: column;
  grid-area: extracontent;
  overflow: auto;
  position: relative;
  width: 100%;
}

@media (--breakpoint-mobile) {
  .layout {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    background-color: var(--grey-9-color);
    @media print {
      display: block;
    }
  
    &-grid{
      display: grid;
      grid-template-areas: 'sidebar header' 'sidebar extracontent' 'sidebar content';
      grid-template-columns: 0px 1fr;
      grid-template-rows: 66px min-content 1fr;
      height: 100vh;
    }
  
    &-page {
      display: flex;
      flex: 1;
      @media print {
        display: block;
      }
    }
  
    &-content {
      display: flex;
      overflow: auto;
      flex: 1;
      flex-direction: column;
      grid-area: content;
      background-color: var(--grey-9-color);
      padding-bottom: 88px;
      @media print {
        display: block;
        overflow: visible !important;
      }
    }
  }
}