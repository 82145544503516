@import 'variables.pcss';

.tooltip {
    z-index: 1;
    border-radius: 3px;
    visibility: hidden;
    position: relative;
    width: 154px;
    font-size: 12px;
    background-color: #454953;
    padding: 6px;
    color: white;

    &-container{
        width: 100%;
        height: 100%;
        position: absolute;
        pointer-events: none;
    }

    &-icon{
        position: absolute;
        top: -15px;
        left: 240px;
        color: #454953;
        background-color: transparent;
        pointer-events: all;

    }
    &-icon:hover + .tooltip {
        visibility: visible;
    }
}

.tooltipError {
    @extend .tooltip;
    position: absolute;
    top: -29px;
    width: max-content;
    right: 0px;
    justify-content: right;
    margin-left: auto;
    background-color: var(--message-error-light-color);
    color: var(--message-error-color);
    font-size: 10px;
    padding: 6px 9px;
    }

.error:hover > .tooltip{
    color: var(--message-error-color);
    visibility: visible;
}

.tooltip:after{
    content: " ";
    position: absolute;
    top: 100%;  /* At the top of the tooltip */
    left: 90%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #454953 transparent transparent  transparent;
}

.tooltipError:after {
    content: " ";
    position: absolute;
    top: 100%;  /* At the top of the tooltip */
    left: 90%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: var(--message-error-light-color) transparent transparent  transparent;
}
