@import 'variables.pcss';


.sidebar {
  position: relative;
  grid-area: sidebar;

  @media (min-width:1401px) {
    min-width: 65px;
  }
  @media (min-width:901px) and (max-width:1401px) {
    min-width: 65px;
  }

  background-color: var(--light-background-color);
  box-shadow: 0px 16px 16px rgba(0, 0, 0, 0.24), 0px 0px 16px rgba(0, 0, 0, 0.18);
  z-index: 10;

  &-heading{
    display: flex;
    justify-content: space-evenly;
    margin: 15px 0 20px 0;

    @media (--breakpoint-desktop) {
      &-img{
        width: 145px;
        max-width: 90vw;
  
        &-minimized {
          width: 24px;
        }
      }
    }

    &-logo{
      font-size: 17px;
      @media (--breakpoint-mobile) {
        font-size: 2em;
      }
    }

    &-link{
      display: flex;
    }

    &-chevron{
      align-self: center;
      transition: transform 0.3s ease-in-out;
      @mixin no-select;

      @media (max-width: 1400px) {
        display: none;
      }

      &-maximize{
        transform: rotate(180deg);
      }
    }
  }

  &-inner {
    display: flex;
    flex-direction: column;

    &-float {
      position: absolute;
      height: 100vh;
      @media (min-width:1401px) {
        min-width: 230px;
      }
      @media (min-width:901px) and (max-width:1400px) {
        min-width: 230px;
      }
      @media (max-width:900px) {
        min-width: 230px;
      }
      background-color: var(--light-background-color);
      box-shadow: 0px 16px 16px rgba(0, 0, 0, 0.24), 0px 0px 16px rgba(0, 0, 0, 0.18);
    }
  }

  &-hide {
    display: none;
  }

  &-button {
    @mixin sidebar-font;
    color: #3e5a6c;
    position: relative;
    text-decoration: none;
    padding: 7px 10px;
    margin: 6px 10px;
    cursor: pointer;
    border: none;
    border-radius: 8px;
    transition: all .3s;
    display: flex;

    &:focus {
      outline: none;
    }

    &:hover {
      color: var(--main-color);
      background-color: rgba(66, 148, 136, 0.15);
    }

    &-active {
      color: var(--main-color);
      background-color: rgba(170, 217, 208, 1);
    }
    &-active:hover {
      color: var(--main-color);
      background-color: rgba(170, 217, 208, 1);
    }

    &-icon {
      @media (min-width:1401px) {
        width: 22px !important;
        height: 22px;
      }
      @media (min-width:901px) and (max-width:1400px) {
        width: 20px !important;
        height: 20px;
        padding: 0 0 0 2px;
      }
      @media (max-width:900px) {
        width: 20px !important;
        height: 20px;
        padding: 0 0 0 5px;
      }
    }

    &-text {
      align-self: center;
      @media (min-width:1401px) {
        margin-left: 13px;
      }
      @media (min-width:901px) and (max-width:1400px) {
        margin-left: 13px;
      }
      @media (max-width:900px) {
        margin-left: 13px;
      }
    }
  }
  &-firstSteps{
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    padding: 0;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;

    &-mini{
      margin: 6px 3px;
      display: flex;
      justify-content: center;
      color: var(--primary-color);
      font-weight: 500;
    }

    &-title{
      align-self: start;
      padding: 12px;

      &-progress{
        color: var(--primary-color);
        font-weight: 500;
        padding: 12px;
      }
    }

    &-header{
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &-progress{
      &-empty{
        background-color: var(--primary-lighter-color);
        height: 4px;
        border-radius: 8px;
      }
      &-done{
        width: 30%;
        background-color: var(--primary-color);
        height: 4px;
        border-radius: 8px;
      }
    }
    &-bar{
      &-shine{
        background: linear-gradient(90deg, var(--primary-color) 35%, rgba(255,255,255,0.75) 50%, var(--primary-color) 65%) #E3EFED;
        background-size:300% 100%;
        animation: shine 1.5s infinite;
      }
    }
  }

  &-additionalLinks{
    &-button{
      @mixin sidebar-font;
      color: #3e5a6c;
      position: relative;
      text-decoration: none;
      padding: 7px 10px;
      margin: 6px 10px;
      cursor: pointer;
      border: none;
      border-radius: 8px;
      transition: all .3s;
      display: flex;
      align-items: center;
      width: fit-content;
    }
    &-container{
    position: absolute;
    width: max-content;
    right: -184px;
    top: -29px;
    background-color: #fff;
    -ms-flex-pack: center;
    -ms-flex-align: center;
    -webkit-box-shadow: 1px 1px 15px rgb(79 99 116 / 30%);
    box-shadow: 1px 1px 15px rgb(79 99 116 / 30%);
    border-radius: 8px;
    padding: 5px 0;
    }

    &-arrow{
    position: absolute;
    height: 30px;
    width: 30px;
    background-color: white;
    transform: matrix(.71,-.71,.69,.71,0,0);
    top: 28px;
    left: -4px;
    }

    &-text{
      color: #A3A9B9;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      @media (min-width:1401px) {
        margin-left: 5px;
      }
      @media (min-width:901px) and (max-width:1400px) {
        margin-left: 5px;
      }
      @media (max-width:900px) {
        margin-left: 5px;
      }
    }

    &-icon{
      color: #A3A9B9;
      @media (min-width:1401px) {
        width: 22px !important;
        margin-left: 13px;
      }
      @media (min-width:901px) and (max-width:1400px) {
        width: 20px !important;
        text-align: center;
      }
      @media (max-width:900px) {
        width: 20px !important;
      }
    }
  }
}

.isNew {
  position: absolute;
  top: 5px;
  left: 5px;
  background-color: var(--mark-red-color);
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.4);
  color: white;
  font-size: 8pt;
  padding: 3px;
  border-radius: 10px;
  font-weight: normal;
}

@media print {
  .sidebar {
    display: none;
  }
}

@keyframes shine {
  0% {
    background-position: right;
  }
}

@keyframes right {
  from {transform: rotate(0deg);}
  to {transform: rotate(180deg);}
}

@keyframes left {
  from {transform: rotate(180deg);}
  to {transform: rotate(0deg);}
}


@media (--breakpoint-mobile) {
  .sidebar{
    margin-left: -100vw;
    position: relative;
    grid-area: sidebar;
    width: 100vw;
    height: 100vh;
    position: fixed;
    /* margin-left: 0; */
    box-shadow: none;
    transition: all 0.3s ease-in-out;
    z-index: 600;

    &-heading{
      display: flex;
      justify-content: center;
      align-items: center;

      &-link{
        height: 100%;
        justify-content: center;
        align-items: center;
      }

      &-icon{
        height: 100%;
        max-height: 80px;
        width: auto;
      }

      &-img {
        max-width: calc(100% - 50px);
        max-height: 50%;
      }
    }

    &-button{
      font-size: 20px;
      font-weight: 400;
    }

    &-hide {
      display: inline-block;
    }

    &-isSwiping{
      transition: none;
    }

    &-touch{
      &-area{
        width: 20vw;
        right: -10vw;
        z-index: 9;
        position: absolute;
        height: 100vh;
      }
    }
  }
}
