@import 'variables.pcss';

.table {
  border-collapse: collapse;
  width: 100%;
  display: flex;
  flex-flow: column;
  overflow-y: auto;

  &-row {
    color: var(--primary-font-color);
    font-family: "Roboto", sans-serif;
    @media (min-width:1251px) {
      font-size: 14;
    }
    @media (min-width:901px) and (max-width:1250px) {
      font-size: 12;
    }
    @media (max-width:900px) {
      font-size: 11;
    }

    height: 40px;
    display: table;
    width: 100%;
    cursor: pointer;
  }

  &-cell{
    padding: 0px 12px;
    overflow-wrap: break-word;
    word-break: break-word;
    hyphens: auto;
  }

  &-header {
 
    &-row{
      background-color: var(--grey-9-color);
      height: 32px;
      color: var(--secondary-color);
      font-weight: 500;
    }

    &-cell{

    }
  }
  
  &-body {
    @media (max-height: 1199px) {
      max-height: 62vh;
    }
    @media (min-height: 1200px) {
      max-height: 75vh;
    }
    overflow-y: auto;
    flex: 1;


   &-row {
    border-bottom: 1px solid var(--border-color);
   }

  &-cell {


  }

  }
}
