.backgroundBlob{
    position: fixed;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;

    &-background{
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: var(--primary-lighter-color);
        
        &-blob{
            min-width: 800px;
        }
    }
}