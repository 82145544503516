@import 'variables.pcss';

.number-input {
  position: relative;

  &-label {
    display: flex;
    flex-direction: row;

    &-column {
      flex-direction: column;
    }

    &-text {
      @mixin text-font;
      white-space: nowrap;
      margin-right: 5px;
      margin-bottom: 6px;
      display: flex;
      align-items: center;
      background-color: var(--light-background-color);
      font-size: 85%;
      position: absolute;
      top: -5px;
      left: 5px;
      padding: 0 3px;
    }
  }

  &-required-mark {
    padding: 0px 0px 0px 4px;
    color: var(--error-color);
  }

  &-field {
    display: flex;
    flex-direction: row;

    &-input {
      @mixin text-font;
      border: 1px solid rgba(0, 0, 0, 0.12);
      border-radius: 2px;
      box-sizing: border-box;
      padding: 9px;
      width: 100%;

      &-with-arrows {
        width: calc(100% - 20px);
      }

      &-without-arrows {
        padding-right: 16px;
        width: 100%;
      }
    }

    &:focus {
      outline: none;
      border-color: #f7f7f7;
    }

    &-error {
      border: 1px solid var(--error-border-color) !important;
    }

    &-disabled {
      background-color: var(--disabled-background-color);
      color: var(--disabled-font-color);
    }

    &-withUnit {
      padding-right: 12px;
    }
  }

  &-arrows {
    margin-left: 4px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &-arrow-button {
    background-color: unset;
    border: 1px solid rgba(0, 0, 0, 0.12);
    padding: 4px 4px;
  }

  &-up-arrow {
    width: 0;
    height: 0;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-bottom: 5px solid rgba(0, 0, 0, 0.52);
  }

  &-down-arrow {
    width: 0;
    height: 0;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 5px solid rgba(0, 0, 0, 0.52);
  }

  &-error {
    @mixin error-font;
    margin-top: 4px;
  }

  &-incr {
    color: #aaa;
    display: flex;
    flex-direction: column;
    position: absolute;
    top: -3px;
   right: 4px;
  }

  &-plus {
    font-size: 15px;
    padding-top: 2px;
    cursor: pointer;
  }
  &-plus:hover {
    color: rgba(62, 90, 108, 0.8);
  }

  &-minus {
    margin-top: -2px;
    font-size: 15px;
    cursor: pointer;
  }
  &-minus:hover {
    color: rgba(62, 90, 108, 0.8);
  }

  &-unit {
    color: #666;
    display: inline-block;
    position: absolute;
    top: 8px;
  }
}
