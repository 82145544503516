@import 'variables.pcss';

.lottieContainer{
  width: 100%;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.spinner {
  padding: 100px 0;
  flex: 1;
  justify-content: center;
  align-items: center;
  display: flex;

  &-bounce {
    width: 18px;
    height: 18px;
    background-color: var(--main-color);

    border-radius: 100%;
    display: inline-block;
    animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  }
}

.onScrollSpinner {
  margin: 12px 45%;
  width: 30px;
  text-align: center;

  &-bounce {
    width: 10px;
    height: 10px;
    background-color: var(--main-color);

    border-radius: 100%;
    display: inline-block;
    animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  }
}

.onDropdownSpinner {
  margin: 0px 45%;
  width: 30px;
  text-align: center;
}


.bounce1 {
  animation-delay: -0.32s;
}

.bounce2 {
  animation-delay: -0.16s;
}

@keyframes sk-bouncedelay {
  0%, 80%, 100% {
    transform: scale(0);
  } 40% {
    transform: scale(1.0);
  }
}
