@import "variables.pcss";

.required-mark {
  padding: 0px 0px 0px 4px;
  color: var(--error-color);
}

.isRequired
{
  color: var(--message-error-color);
}

.input {
  position: relative;

  &-container {
    display: flex;
    align-items: center;
    border-radius: 8px;
    transition: border-color;
    cursor: text;
    &-border {
      outline: 1px solid var(--border-color);
      transition: border-color;

      &-focused {
        outline-color: var(--primary-color);
      }

      &-searchFocused {
        outline-color: var(--highlight-color);
        outline-width: 2px;
      }

      &-invalid {
        outline-color: var(--error-border-color);
      }
  
      &-invalid:hover {
        outline-color: var(--error-border-color);
      }
  
      &-error {
        outline-color: var(--error-border-color);
      }
    }
  }

  &-label {
    @mixin text-font;
    display: flex;

    &-column {
      flex-direction: column;
    }

    &-text {
      white-space: nowrap;
      margin-right: 5px;
      margin-bottom: 6px;
      display: flex;
      align-items: center;
      background-color: transparent;
      font-size: 12px;
      position: absolute;
      top: -15px;
    }
  }

  &-upper-right-label {
    @mixin text-font;
    position: absolute;
    transform: translateY(calc(-100% - 1.5px));
    right: 8px;
    min-height: 12px;
    font-size: 12px;
  }

  &-field {
    @mixin text-font;
    resize: none;
    width: 100%;
    border: none;
    border-radius: 8px;
    overflow: hidden;
    background-color: var(--light-background-color);
    line-height: 20px;

    &:focus {
      outline: none;
      /*border: 1px solid var(--primary-color);;
      border-radius: 8px;
      padding: 9px 8px 9px 40px;*/
    }

    &::placeholder {
      color: var(--disabled-font-color);
    }

    &-withIcon {
      padding-right: 37px !important;

      &-left {
        padding-right: 9px !important;
        padding-left: 37px !important;
      }
    }

    &-field {
        @mixin text-font;
        resize: none;
        width: 100%;
        border: none;
        border-radius: 8px;
        overflow: hidden;
        background-color: var(--light-background-color);
        line-height: 20px;

        &::placeholder {
            color: var(--disabled-font-color);
        }

        &-date {
            padding: 6px 9px;
        }

        &-default {
            padding: 7.5px;
        }

        &[disabled] {
            background-color: var(--disabled-background-color);
            color: var(--disabled-font-color);
        }
    }

    &-suffix{
      padding-right: 9px;
    }

    &-default {
      padding: 7.5px;
    }

    &[disabled] {
      background-color: var(--disabled-background-color);
      color: var(--disabled-font-color);
    }

    &-icon {
      cursor: pointer;
      background-color: transparent;
      border: none;
      position: absolute;
      outline: none;
      right: 10px;
      top: 8px;
      color: #a3a9b9;
      width: 19px !important;
      height: 19px !important;

      &-left{
        left: 10px;
      }

      &-notClickable{
        pointer-events: none;
      }
    }
  }

  &-error {
    height: 1em;
    padding-top: 3px;
    color: var(--error-color);
    font-size: 11px;
  }
}
