@import 'variables.pcss';

.label {
  padding: 5px;
  color: var(--white-color);
  display: inline-block;
}

.draft {
  background-color: var(--draft-color);
}
.imported {
  background-color: var(--draft-color);
}
.preview {
  background-color: var(--preview-color);
}
.open {
  background-color: var(--open-color);
}
.overdue {
  background-color: var(--due-color);
}
.paid, .paid-out {
  background-color: var(--paid-color);
}
.active {
  background-color: var(--draft-color);
}
.paidCredit {
  background-color: var(--status-credit);
}
.offer {
  background-color: var(--highlight-color);
}
.costListing {
  background-color: #D2DDEE;
}
