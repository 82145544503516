@import 'variables.pcss';

.link-button{

    &-disabled * {
        pointer-events: none;
        color: gray !important;
    }

    &-container {
        align-items: center;
        display: flex;
        flex-direction: row;
    }

    &-editIcon {
        height: 20px;
        width: 20px;
    }

    &-faIcon {
        margin-left: 5px;
    }

    &-link {
        cursor: pointer;
        border: none;
        background: transparent;
        padding: 0;
        margin: 1px;
        text-align: left;
        text-decoration: underline;
        border: 1.5px solid transparent;
        padding: 2px;
        
        &:focus-visible {
            outline: none;
            border: 1.5px solid var(--primary-color);
            border-radius: 8px;
            padding: 2px;
        }
    }
}