.accordion{
    transition: margin 0.2s;
    margin: 2px;

    &-title{
        width: 100%;
    }

    &-active{
        margin: 10px 0;
    }

    &-header{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px;
        cursor: pointer;
        background: #F0F2F5;
        border-radius: 4px;
        font-size: 16px;
        font-weight: 400;
        transition: all 0.2s;

        &-active{
            background: var(--primary-light-color);
            border-radius: 4px 4px 0px 0px;
        }
    }

    &-body{
        overflow: hidden;
        transition: all 0.2s ease;
        position: relative;
        width: 100%;
        background: rgba(66, 148, 136, 0.15);
        border-radius: 0px 0px 8px 8px;

        &-content {
            padding: 8px 10px 16px 10px;
        }
    }

    &-icon{
        transition: all 0.2s;
        transform: rotate(0deg);
        height: 24px;
        width: 24px;
        &-active{
            transform: rotate(180deg);
        }
    }
}
