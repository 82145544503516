@import 'variables.pcss';

.bannerContainer{
    position: relative;
    width: 100%;
    height: 0px;
    overflow: hidden;
    transition: all 1s;

    .banner{
        width: 100%;
        box-shadow: 0 4px 4px rgba(0, 0, 0, .24), 0 0 4px rgba(0, 0, 0, .12);
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        height: 100%;
        &-color{
            background-color: brown;
            color: white;
        }
    }
}

.choosePlanBanner{
    background-color: rgba(255, 176, 58, 0.30);
    color: var(--secondary-color);

    &-Button{
        background-color: var(--highlight-color);
        color: var(--secondary-color);
        margin-left: 16px;
    }
}