@keyframes moveInBottom {
  0% {
      margin-bottom: -100%;
  }
  100% {
      margin-bottom: 0%;
  }
}

@keyframes fadeIn {
  0% {
      opacity: 0;
  }
  100% {
      opacity: 1;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}