.noEntities {
  &-content {
    display: flex;
    justify-content: center;
    padding: 70px;
  }

  &-create {
    position: absolute;
    top: 25px;
    right: 25px;
  }

  &-text {
    font-size: 20px;
    font-weight: 500;
    color: #CDCCCC;
  }
}
