@import 'variables.pcss';

.noEntities {
    &-content {
        display: flex;
        justify-content: center;
        padding: 45px;
    }

    &-create {
        text-align: center;
        margin: auto;
    }

    &-text {
        font-size: 20px;
        font-weight: 500;
        color: #CDCCCC;
    }
}

@media (--breakpoint-mobile){
    .noEntities{
        &-content{
            padding: 0px;
        }
    }
}
