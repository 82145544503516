@import 'variables.pcss';

.displayContainer{
    position: fixed;
    bottom: 10px;
    right: 10px;
    z-index: 20;
    user-select: none;
    min-width: 200px;
    max-width: 400px;

    &-arrow {
        float: right;
        cursor: pointer; 
    }

    &-content {
        overflow-x: hidden;
        overflow-y: auto;
        @mixin text-font;
        display: block;
        border-radius: 5px;
        max-height: 100px;

        &-card {
            background-color: rgba(199, 199, 199, 0.85);
            color: white;
            padding: 5px;

            &-downloadBar {
                margin: 2px 0;
                background-color: var(--add-button-color);
                height: 4px;
                width: 100%;
                border-radius: 2px;
            }
        }
    }
}