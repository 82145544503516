@import 'variables.pcss';

.accordion{
    transition: margin 0.2s;
    border: 2px solid var(--grey-7-color);
    border-radius: 8px;
    &-title{
        width: 100%;
    }

    &-header{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px;
        cursor: pointer;
        border-radius: 4px;
        font-size: 16px;
        font-weight: 400;
        transition: all 0.2s;
        gap: var(--spacing-m);
    }

    &-body{
        overflow: hidden;
        transition: all 0.2s ease;
        position: relative;
        width: 100%;

        &-content {
            padding: 8px 10px 16px 10px;
        }
    }

    &-icon{
        transition: all 0.2s;
        transform: rotate(0deg);
        height: 24px;
        width: 24px;
        &-active{
            transform: rotate(180deg);
        }
    }
}
