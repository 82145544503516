@import 'variables.pcss';

.message {
    border-radius: 8px;
    color: black;
    padding: 10px 10px 10px 10px;
    align-content: center;
    display: flex;
    flex-direction: row;
    width: fit-content;
    margin-bottom: 15px;
    align-items: center;
    box-sizing: border-box;
    &-icon {
        padding-right: 7px;
        min-height: 20px;
        min-width: 20px;
        max-height: 20px;
        max-width: 20px;
    }

    &-marginLeft {
        margin-left: 16px;
    }

}

.warning {
    background-color: var(--message-warning-light-color);

    &-icon {
        color: var(--message-warning-color);
    }

    &-bordered {
        border: 1.5px solid var(--message-warning-color);
    }
}

.error {
    background-color: var(--message-error-light-color);

    &-icon {
        color: var(--message-error-color);
    }

    &-bordered {
        border: 1.5px solid var(--message-error-color);
    }
}

.success {
    background-color: var(--message-success-light-color);

    &-icon {
        color: var(--message-success-color);
    }

    &-bordered {
        border: 1.5px solid var(--message-success-color);
    }
}

.info {
    background-color: var(--message-information-light-color);

    &-icon {
        color: var(--message-information-color);
    }

    &-bordered {
        border: 1.5px solid var(--message-information-color);
    }
}
