.accordion{
  
  border-bottom: 1px solid var(--grey-2-color);
  
  &:last-child {
      border: none;
  }

  &-title{
      width: 100%;
  }

  &-active{
  }

  &-header{
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 16px;
      padding: 6px 10px 6px 8px;
      cursor: pointer;
      transition: all 0.2s;
      align-items: center;

      &-active{
      }
  }

  &-body{
      overflow: hidden;
      transition: all 0.2s ease;
      position: relative;
      width: 100%;
      
      &-content {
        padding: 6px 0px 9px 0px;
      }
  }

  &-icon{
      transition: all 0.2s;
      transform: rotate(0deg);
      &-active{
          transform: rotate(180deg);
      }
  }
}
