@import 'variables.pcss';

.lazyLoadingList {
    height: 100%;
}

.tableStyles > div > div{
    &:last-child {
        border-bottom: 2px solid #ddd;
    }
}

.extraContent{
    position: relative;
    &-empty{
       height: 100%;
    }
    &-notFound{
        height: 100%;
    }
}

.fabPlaceHolder{
    height: 80px;
}

@media(--breakpoint-mobile){
    .extraContent{
        height: 100%;
        &-notFound{
            display: flex;
            height: 100%;
            align-items: center;
            background-color: white;
            justify-content: center;
        }
    }
}


@media (--breakpoint-mobile) {
    ::-webkit-scrollbar { 
    display: none; 
    }
}
