@import 'variables.pcss';

.previous {
  width: 350px;
  padding: 10px;

  &-title {
    @mixin tableheader-font;
  }

  &-table {
    margin-top: 10px;
    border: 1px solid var(--border-color);
  }

  &-button {
    border: unset;
    background: unset;
    height: 100%;
    width: 100%;
    text-align: left;

    &:hover {
      background-color: var(--select-color);
    }
  }

  &-text {
    margin-top: 10px;
  }

  &-cell {
    padding: 0;
  }
}