@import 'variables.pcss';

.containerCard{
  display: flex;
  justify-content: space-between;
  padding: 5px 0 15px 0;
  background: #FFFFFF;
  box-shadow: 1px 1px 5px rgba(79, 99, 116, 0.3);
  border-radius: 8px;
  overflow: hidden;

    &-selected{
      background-color: var(--primary-lighter-color);
    }

    &-hover:hover{
      background-color: #E3EFED;
      cursor: pointer;
    }

    &-deleted{
      background-color: var(--grey-9-color);
    }

    &-onRemove{
      background-color: var(--message-error-light-color);
      transition: background-color 0.3s ease-in-out;
    }

    &-data{
      display: inline-block;
      height: 100%;
      vertical-align: top;
      max-width: 85%;
    }
  
    &-element{
      align-items: center;
      vertical-align: middle;
      white-space: pre-wrap;
      font-size: 14px;
      line-height: 16px;
      color: #26282D;
      font-weight: normal;
      display: flex;
      padding: 10px 0 0 15px;
      line-height: 20px;

      &-name{
        font-weight: 500;
        font-size: 16px;
        padding: 15px 0 0 15px;
      }

      &-actions{
        justify-content: flex-end;
        display: flex;
        padding: 15px 15px 0 0;
        gap: 10px;
        display: flex;

        &-noPadding{
          padding: 0;
        }
      }

      &-ellipsis{
        max-height: 1lh;
        text-overflow: ellipsis;
        overflow: hidden;
      }

    }

    &-iconAndActions{
      display: flex;
      flex-flow: column;
    }
  }
  
.icon{
  color: #4F6374;
  padding-right: 15px;
  align-self: center;

  &-alignSpace{
    margin-right: 5px;
  }

  &-action{
    font-size: 16px;
    padding: 0;
    cursor: pointer;

    &-remove:hover{
      color: var(--message-error-color)
    }
  }
}

.containerImage{
  &-user{
    width: 50px;
    font-size: 70px;
    color: #42948833;
    margin-top: auto;
    &-icon{
      margin-top: auto;
      height: 100%;
    }
    &-white{
      color: #FFFFFF;
      margin-bottom: auto;
      transition: color 0.3s ease-in-out;
    }
  }


  &-icon{
    vertical-align: bottom;
      width: 60px;
      height: 70px;
      transform: rotate(0, 90deg);
      transform:scale(-1 1);
  }
}
  
.containerAction{
  cursor: pointer;
  display: flex;
  justify-content: end;

  a {
    text-decoration: none;
  }

  &-icon{
    color: #4F6374;
  }

  &-text{
    margin-left: 5px;
    display: inline-block;
  }
}

.seperator{
  background-color: #E0E0E0;
  height: 1px;
  margin: 0px;
}

.truncateFormat{
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 60vw;

  &-card{
    &-name{
      max-width: 70vw;
    }
  }
  &-details{
    &-address{
      max-width: 75vw;
    }
  }
}

.header{
  padding: 15px;

  &-content{
    display: flex;
    width: 100%;
    align-items: end;
    gap: 15px;

    &-name{
      flex: auto;
    }
  }
  

  &-icon{
    font-size: 80px;
    color: #42948833;
    margin-left: -40px;
  }

  &-name{
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.15px;

    &-withoutLink{
      color: var(--secondary-color);
    }
  }
  &-actions{
    display: flex;
    flex-flow: column;
    gap: 10px;
    margin-bottom: auto;
  }
}

.noTextDecoration{
  text-decoration: none;
}

.link{
  color: var(--primary-color);
  @mixin link-modern;
}

.details{
  box-shadow: 1px 1px 5px rgba(79, 99, 116, 0.3);
  border-radius: 8px;
  overflow: hidden;
  background: #FFFFFF;

  &-container{
    flex-flow: column;
    display: flex;
    gap: 15px;
    
    padding: 15px;
  }

  &-info{
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
  }

  &-cardData{
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  &-grid{
    display: grid;
    flex-grow: 1;
    flex-shrink: 0;
    gap: 15px;
  }

  &-icon{
    color: #4F6374;
    align-self: center;
  }

  &-row{
    display: flex;
    gap: 15px;
    white-space: break-spaces;
    align-items: center;
  }
  &-group{
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 10px;
    width: 100%;
    &-row{
      &-title{
        font-size: 12px;
        color: var(--grey-4-color);
      }
      &-content{
        text-align: end;
      }
    }
    
  }
}

@media(--breakpoint-mobile){
  .details{
    border-radius: unset;

    &-cardData{
      grid-template-columns: 1fr;
    }
  }
}