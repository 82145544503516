.no-entity {
  &-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
  }

  &-button {
    white-space: nowrap;
  }

  &-description {
    margin-top: auto;
    width: 270px;

    & p {
      line-height: 18px;
    }

    &-text {
      white-space: pre-wrap;
    }
  }

  &-create-button {
    display: inline-block;
    padding: 5px;
    width: auto;
    margin-left: 5px;
    font-size: 13px;
    vertical-align: middle;
  }

  &-select-button-wrapper {
    margin: auto 20px 20px auto;
  }
}
