@import 'variables.pcss';


.required-mark {
  padding: 0px 0px 0px 4px;
  color: var(--error-color);
}

.input-dropdown {
  position: relative;

  &-wrapper {
    position: relative;
  }

  &-label {
    @mixin text-font;
    margin-bottom: 5px;
    white-space: nowrap;
    display: flex;
    background-color: var(--light-background-color);
    font-size: 85%;
    position: absolute;
    top: -5px;
    left: 5px;
    padding: 0 3px;
  }

  &-input {
    @mixin text-font;
    cursor: pointer;
    outline: none;

    &-button {
      @mixin text-font;
      padding: 9px;
      background-color: white;
      overflow: hidden;
      text-align: left;
      width: calc(100% - 20px);

      &:focus {
        outline: none;
      }

      &-icon {
        float: right;
        background-color: transparent;
        border: none;
        position: absolute;
        outline: none;
        right: 0px;
        top: 5px;
        margin: 4px 2px 0px 0px;

        &-arrow {
          transition: transform .25s;
          pointer-events: none;

          &-open {
            transform: rotate(180deg);
          }
        }
      }
    }

    &-list {
      @mixin text-font;
      width: calc(100% - 2px);
      border: 1px solid var(--border-color);
      position: absolute;
      box-sizing: border-box;
      background-color: var(--light-background-color);
      border-radius: 2px;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      transform: scaleY(1);
      transition: transform .25s;
      transform-origin: right top;
      z-index: 9999;
      overflow-y: auto;
      max-height: 160px;

      &-on-top {
        bottom: 56px;
      }

      &-wider {
        width: auto !important;
      }

      &-item {
        @mixin text-font;
        border: none;
        cursor: pointer;
        background-color: var(--light-background-color);
        padding: 6px;
        text-align: left;
        border-radius: 0;
        transition: all .3s;

          &-active {
            background-color: var(--dark-background-color);
          }

          &-selected {
            font-weight: 500;
          }

        &:focus {
          outline: none;
        }

        &:hover {
          background-color: var(--dark-background-color);
        }
      }

      &-hidden {
        transform: scaleY(0);
      }
    }
  }

  &-error {
    height: 20px;
    padding: 3px 0;
    color: var(--error-color);
    font-size: 11px;
  }
}
