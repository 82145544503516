@import 'variables.pcss';


.breadcrumbs {
  display: flex;
  padding: 30px 20px 0 20px;
  text-transform: uppercase;
}

.link {
  text-decoration: none;
  color: var(--primary-font-color);
}
