@import 'variables.pcss';


.tires-actions {
  &-button {
    margin-left: 10px;
  }
}

.tires-status {
  width: 80px;

  &-label {
    padding: 3px;
    background: rgba(255, 240, 63, 0.8);
    color: var(--primary-font-color);

    &-removed {
      background: var(--draft-color);
      color: var(--disabled-font-color);
    }
  }
}

.table-actions {
  width: 130px;
}

.dymoEnabled{
  .table-actions {
    width: 160px;
  }
}

.tires-filter-settings {
  display: flex;
  align-items: center;
}
.tire {
  &-stored-period {
    width: 100px;
    margin: 0 5px;

    &-label {
      display: flex;
      align-items: center;
      margin: 0 10px;
    }
  }
  
  &-show-removed {
    height: 20px;
    width: 20px;
  }
}
.tiresStatusField{
  border-radius: 20px;
  width: 67px;
}

.icon {
  margin-right: 6px;
}

.status_orange{ 
  background: var(--message-warning-light-color);
  color: var(--message-warning-color);
}

.status_red{
  background: var(--message-error-light-color);
  color: var(--message-error-color);
}

.status_green{  
  background: var(--message-success-light-color);
  color: var(--message-success-color);
}

@media print {
  .table-actions,.tire-show-removed {
    display: none;
  }

  .tires-filter-settings > div {
    display: none;
  }
}