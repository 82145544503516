@import 'variables.pcss';


.required-mark {
  padding: 0px 0px 0px 4px;
  color: var(--error-color);
}

.dropdown {
  position: relative;

  &-label {
    @mixin text-font;
    display: flex;

    &-column {
      flex-direction: column;
    }

    &-text {
      white-space: nowrap;
      margin-right: 5px;
      margin-bottom: 5px;
      display: flex;
      align-items: center;
      height: 10px;
      min-height: 13px;
      background-color: var(--light-background-color);
      font-size: 85%;
      position: absolute;
      top: -5px;
      left: 5px;
      padding: 0 3px;
    }
  }

  &-input {
    position: relative;
    cursor: pointer;
    width: 100%;

    &-button {
      @mixin text-font;
      padding: 9px;
      background-color: transparent;
      border: 1px solid var(--border-color);
      border-radius: 2px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      cursor: pointer;
      text-align: left;
      width: 100%;

      &-disabled {
        background-color: var(--disabled-background-color);
        color: var(--disabled-font-color);
      }

      &-error {
        border-color: var(--error-border-color);
      }

      &-icon {
        float: right;
        background-color: transparent;
        border: none;
        position: absolute;
        outline: none;
        right: 0;
        top: 5px;
        margin: 4px 2px 0px 0px;

        &-arrow {
          transition: transform .25s;
          pointer-events: none;

          &-open {
            transform: rotate(180deg);
          }
        }
      }
    }

    &-list {
      @mixin text-font;
      width: calc(100% - 2px);
      border: 1px solid var(--border-color);
      position: absolute;
      background-color: var(--light-background-color);
      border-radius: 2px;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      transform: scaleY(1);
      transition: transform .25s;
      transform-origin: right top;
      z-index: 1;
      overflow-y: auto;
      max-height: 160px;
      list-style-type: none;

      &-on-top {
        bottom: 56px;
      }

      &-wider {
        width: auto !important;
      }

      &-item {
        @mixin text-font;
        border: none;
        overflow: visible !important;
        cursor: pointer;
        background-color: var(--light-background-color);
        padding: 6px;
        text-align: left;
        border-radius: 0;
        transition: all .3s;

        &-active {
          background-color: var(--dark-background-color);
        }

        &-selected {
          font-weight: 500;
        }

        &:focus {
          outline: none;
        }

        &:hover {
          background-color: var(--dark-background-color);
        }
      }

      &-hidden {
        transform: scaleY(0);
      }
    }
  }

  &-error {
    height: 20px;
    padding: 3px 0;
    color: var(--error-color);
    font-size: 11px;
  }
}
