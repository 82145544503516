@import 'variables.pcss';


.container {
  margin-bottom: 15px;
  display: flex;
  flex-direction: row;
}

.printAndDownload {
  height: 36px;
  width: 48px;
  display: flex;
  justify-content: center;
  transition: all 0.3s;
  background-color: var(--export-button-color);
  border-radius: 2px;
  box-shadow: 0 2px 2px rgba(0,0,0,.24), 0 0 2px rgba(0,0,0,.12);
  
  &:focus-within{
    outline: auto;
  }

  &:hover{
    background-color: #8c8c8c;
  }

  svg{
    color: hsla(0,0%,100%,.84);
    margin: 0;

    &:hover{
      color: hsla(0,0%,100%,.84);
    }
  }
}

.tire-form {
  height: 95%;
  min-width: 720px;
  width: 70%;

  &-columns {
    display: flex;
    justify-content: center;
    flex-direction: row;
    padding: 5px 18px;
    position: relative;
    min-height: 647px;
  }

  &-elements {
    width: 49%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  &-element {
    width: 49%;
  }

  &-column {
    display: flex;
    flex-direction: column;
    max-width: 405px;
    padding: 0 6px;
  }

  &-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 15px;
  }

  &-client {
    width: 100%;
    height: 56px;

    &-row {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      &-actions {
        display: flex;
      }
    }
  }

  &-vehicle {
    height: 56px;
    min-width: 180px;
  }

  &-dimension {
    width: 80%;
  }

  &-smallDropdown {
  }

  &-speed, &-maxLoad {
    width: 100%;
  }

  &-mm {
    padding: 3px;
    padding-top: 10px;
  }

  &-slash {
    font-weight: 400;
    font-size: 22px;
    padding: 5px;
  }

  &-buttons {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    position: absolute;
    bottom: 20px;
    width: calc(100% - 48px);

    &-print-actions {
      margin-bottom: 5px;
      width: 150px;

      &-label{
        margin-bottom: 5px;
      }

      &-buttons{
        display: flex;
        justify-content: space-around;
  
        svg {
          height: 25px;
          width: 25px;
        }
      }
    }
    &-save-actions {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }
  }

  &-button {
    width: 117px;
  }

  &-select {
    &-username {
      margin-right: 10px;
      margin-top: 5px;
    }

    &-button {
      max-width: 60px;
      min-width: 40px;
      margin-right: 10px;
    }
  }

  &-remove-now {
  }

  &-input {
    height: auto;

    &-tire-type {
      width: 159px;
    }

    &-tire-year {
      width: 174px;
    }

    &-additional-info {
      width: 700px;
    }
  }

  &-dropdown {
    height: auto;
    min-width: 50px;

    &-size {
      min-width: 100px;
      height: auto;
    }
  }

  &-tires-block {
    display: flex;
    flex-direction: column;

    &-title {
      padding: 15px 10px;
    }
  }
}
