@import 'variables.pcss';


.tabHeader {
    border-bottom: 2px solid #E3E6E9;
    transform: none;
    box-shadow: none;
    text-transform: none;
    background-color: inherit;
    color: #4F6374;

    &-container{
        display: flex;
        align-items: center;
    }

    &-tab{
        transform: none;
        box-shadow: none;
        text-transform: none;
        padding: 10px;
        width: fit-content;
        background-color: inherit;
        color: #4F6374;
        position: relative;
        border: none;
        font-weight: 400;
        white-space: nowrap;
    }

    &-without-title{
        width: 100%;
        justify-content: center;
        display: flex;
    }
    &-tab:hover{
        background-color: inherit;
    }
    &-tab:before{
        content: '';
        position: absolute;
        right: 0;
        left: 0;
    }
    &-tab:hover:before{
        height: 4px;
        z-index: 1;
        background-color: #AAD9D0;
        border-radius: 4px;
        bottom: -2px;

    }
    &-active{
        color: #429488 !important;
    }
    &-active:before {
        height: 4px;
        z-index: 1;
        background-color: #429488;
        border-radius: 4px;
        bottom: -2px;
    }
    &-active:hover:before {
        height: 4px;
        z-index: 1;
        background-color: #429488;
        border-radius: 4px;
        bottom: -2px;
    }
    &-icon{
        margin-right: 10px;
        display: inline-block;

        &-without-title{
            margin-right: 0px;
        }
    }
}

.tabHeader{ 
    &-tab{
        color: var(--main-color);
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;
        letter-spacing: 0em;
        text-align: left;
        cursor: pointer;
    }
}
@media print {
    .tab-header {
        display: none;
    }
    .tabNav {
        &-tab{
            cursor: pointer;
        }
    }
}

.searchResult {
    position: absolute;
    top: 0;
    left: 90%;
    background: orange;
    border-radius: 15px;
    min-width: 15px;
    height: 15px;
    font-size: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    padding: 2px;

    &-none{
        position: absolute;
        top: 0;
        left: 90%;
        background: #F0F2F5;
        border-radius: 15px;
        min-width: 15px;
        height: 15px;
        font-size: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #4F6374;
        padding: 2px;
    }
}

.container{
    display: flex;
    gap: 16px;
    padding: 0 8px;
    border-bottom: 2px solid var(--grey-8-color);
}