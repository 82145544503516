@import 'variables.pcss';
@import 'animations.pcss';

.required-mark {
    padding: 0px 0px 0px 4px;
    color: var(--error-color);
}

.dropdown {
    position: relative;

    &-label {
        @mixin text-font;
        display: flex;

        &-column {
            flex-direction: column;
        }

        &-text {
            white-space: nowrap;
            margin-right: 5px;
            margin-bottom: 5px;
            display: flex;
            align-items: center;
            font-size: 12px;
            position: absolute;
            top: -15px;
        }
    }

    &-input {
        position: relative;
        cursor: pointer;
        border: 1px solid var(--border-color);
        border-radius: 8px;
        background-color: var(--light-background-color);
        
        &:focus-within {
            border: 1px solid var(--primary-color);
            outline: none;
        }

        &-grayTheme{
            background-color: var(--grey-9-color);
            &:focus-within {
                border: 1px solid var(--grey-6-color);
            }
        }

        &-button {
            @mixin text-font;
            padding: 7.5px;
            padding-right: 20px;
            background-color: transparent;
            border: none;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            cursor: pointer;
            text-align: left;
            width: 100%;
            line-height: 20px;
            border-radius: 8px;

            &-disabled {
                background-color: var(--disabled-background-color);
                color: var(--disabled-font-color);
            }

            &-error {
                border-color: var(--error-border-color);

                &:focus-within {
                    border: 1px solid var(--error-border-color);
                }
            }

            &:focus{
                outline: none;
            }

            &-icon {
                background: transparent;
                border: none;
                outline: none;
                color: #A3A9B9;
                position: absolute;
                right: 0px;
                padding: 6px;
                top: 50%;
                transform: translateY(-50%);

                &-arrow {
                    transition: transform .25s;
                    pointer-events: none;

                    &-open {
                        transform: rotate(180deg);
                    }
                }
            }
        }

        &-list {
            @mixin text-font;
            width: calc(100% - 1px);
            border: 1px solid var(--border-color);
            position: absolute;
            background-color: var(--light-background-color);
            border-radius: 8px;
            overflow: hidden;
            display: flex;
            flex-direction: column;
            transform: scaleY(1);
            transition: transform .25s;
            transform-origin: right top;
            z-index: 3;
            overflow-y: auto;
            max-height: 160px;
            list-style-type: none;
            left: -1px;
            display: grid;

            &-on-top {
                bottom: 56px;
            }

            &-allowOverflow{
                width: max-content;
            }

            &-item {
                @mixin text-font;
                border: none;
                cursor: pointer;
                background-color: var(--light-background-color);
                padding: 6px;
                text-align: left;
                border-radius: 0;
                transition: all .3s;
                text-overflow: ellipsis;
                overflow: hidden;

                &-active {
                    background-color: var(--dark-background-color);
                }

                &-disabled {
                    background-color: var(--dark-background-color) !important;
                    cursor: not-allowed;
                    color: var(--grey-5-color) !important;
                }

                &-selected {
                    font-weight: 500;
                }

                &:focus {
                    outline: none;
                }

                &:hover {
                    background-color: var(--dark-background-color);
                }

                &-allowOverflow{
                    text-overflow: unset;
                    overflow: unset;
                }
                &-info{
                    font-size: 12px;
                    color: var(--grey-4-color);
                }
            }

            &-hidden {
                transform: scaleY(0);
            }
            
            
            @media (--breakpoint-tablet) {
                position: fixed;
                bottom: 0;
                left: 0;
                max-height: 50%;
                width: 100%;
                overflow: auto;
                z-index: 5;
                border-radius: 0;
                overscroll-behavior: contain;
                padding: 10px 10px 20px;
                box-sizing: border-box;
                gap: 3px;
                animation: moveInBottom .3s forwards;

                &-background {
                    background: #00000063;
                    position: fixed;
                    top: 0;
                    left: 0;
                    height: 100%;
                    width: 100%;
                    z-index: 5;
                    animation: fadeIn .3s forwards;
                }

                &-item{
                    font-size: 20px;
                }
            }
        }
    }
}
