@import 'variables.pcss';

.title {
  position: relative;
  padding: 7.5px 12px;

  &-button {
    @mixin tableheader-font;
    width: 100%;
    white-space: nowrap;
    overflow: visible;

    &-text {
      display: inline-block;
      white-space: normal;
    }
  }

  &-icon {
    min-width: 20px;
  }
}

.element {
  padding: 7.5px 15px;
  text-overflow: ellipsis;
  overflow: hidden;

  &-wide {
    width: 20%;
  }

  &-narrow {
    width: 75px;
  }

  &-medium {
    width: 13%;
  }

  &-right {
    text-align: right;
  }

  &-center {
    text-align: center;
  }
}
