@import 'variables.pcss';

.responsiveModal {
    &-wrapper {
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0px;
        left: 0px;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 500;
        background-color: var(--overlay-color);
        overscroll-behavior: contain;
    }
    &-container {
        max-width: 95%;
        max-height: 95%;
        overflow-y: auto;
        overscroll-behavior: contain;
        padding: 30px;
        background-color: white;
        box-shadow:
                0px 11px 15px -7px rgba(0, 0, 0, 0.2),
                0px 24px 38px 3px rgba(0, 0, 0, 0.14),
                0px 9px 46px 8px rgba(0, 0, 0, 0.12);
        border-radius: 8px;
        &-hidePadding{
            padding: 0;
        }
    }
    &-header{
        position: relative;
        display: flex;
        gap: 8px;
        justify-content: space-between;

        &-fixed{
            display: none;
        }
    }
    &-close {
        top: 0;
        right: 0;
        margin-left: auto;
        cursor: pointer;
    }
    &-progressBar{
        height: 100%;
        width: 100%;
        background-color: rgba(66, 148, 136, 0.15);
        border-radius: 50px;
        textAlign: right;
        position: absolute;
      
        &-complition{
          height: 100%;
          width: 25%;
          background-color: #429488;
          border-radius: 50px;
          textAlign: right;
          position: absolute;
          transition: width 0.3s ease-in-out !important;
        }
    }
}

.containerProgressBar{
    width: calc(100% + 60px);
    height: 5px;
    left: -30px;
    top: -30px;
    position: relative;
}

@media(--breakpoint-mobile-l) {
    .responsiveModal {
        &-wrapper {
            align-items: flex-end;
        }

        &-children {
            padding: 0 30px 30px 30px;
        }

        &-container{
            max-width: unset;
            max-height: 100%;
            width: 100%;
            box-sizing: border-box;
            border-radius: 0;
            padding: 0;
        }

        &-header{
            top: 0;
            left: 0;
            box-sizing: border-box;
            z-index: 4;
            padding: 30px 30px 10px 30px;
            margin-bottom: 14px;
            position: sticky;
            background: white;
        }
    }
}
