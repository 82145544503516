@import "variables.pcss";

.cardContentBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  max-width: 235px;
}

.retry {
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 100px 0;

  &-inside {
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: row;
    padding: 5px 0;
  }

  &-tablet {
    height: 100%;
    padding: 0;
  }

  &-text {
    @mixin title-font;
    padding: 15px;
  }
} 

.cardContentBoxMobile {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  width: 100%;
  height: 100%;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.contentBox{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  padding: 100px 64px 100px 100px;
  background-image: url('./svg/background.svg');
  background-repeat: no-repeat;
  background-position: 57%;
  margin: 0;
}

.textGrid {
  display: grid;
  gap: 16px;
  flex-direction: column
}

.buttonGrid {
  display: grid;
  gap: 32px;
  flex-direction: column
}

.header {
  font-style: normal;
  font-weight: 400;
  font-size: 36px;
  line-height: 42px; 
}

.checkConnectionCard
{
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  width: 230px;
}

.text { 
  &-short{
    width: 230px;
    margin-bottom: 32px;
  }
  &-long{
    width: 351px;
    margin-top: 32px;
  }  
}

.subHeader {
  font-style: normal;
  font-weight: 400;
  font-size: 26px;
  line-height: 30px;
  &-card {
    font-size: 18px;
    width: 235px;
  }
}

.chat {
  color: var(--primary-color);
  text-decoration: underline;
  
}
.chat:hover {
  cursor: pointer;
}