@import 'variables.pcss';


.input {
  @mixin text-font;
  padding: 0 15px 0 5px;
  width: 320px;
  border: 1px solid var(--main-color);
  overflow: hidden;
  height: 36px;
  background-color: var(--light-background-color);

  &-right {
    text-align: right;
  }

  &-center {
    text-align: center;
  }

  &-dim {
    @mixin text-font;
    resize: none;
    padding: 9px;
    width: 100% !important;
    overflow: hidden;
    background-color: var(--light-background-color);
    border: 1px solid var(--border-color);
    border-radius: 0;
    transition: border-color;
    box-sizing: border-box;
  }

  &:focus {
    outline: none;
    border-color: var(--primary-font-color);
  }

  &::placeholder {
    color: var(--disabled-font-color);
  }

  &-field {
    @mixin text-font;
    width: 100%;
    background-color: transparent;
    border: none;
  }

  &-search {
    @media (min-width:1251px) {
      width: 250px;
    }
    @media (min-width:901px) and (max-width:1250px) {
      width: 150px;
    }
    @media (max-width:900px) {
      width: 100px;
    }
  }

  &-edit {
    @mixin text-font;
    width: 100%;
    padding: 9px 5px;

    &:focus {
      outline: none;
    }
  }

  &-isUppercase {
    text-transform: uppercase;
  }

  &-inInvoice {
    border-color: #aaa !important;
    &:focus {
      outline: none;
      border-bottom: 3px solid #62737e !important;
    }

    &-label {
      color: #aaa !important;
      font-size: 72% !important;
      top: -6px !important;
      left: 10px;
      background: linear-gradient(180deg, transparent 50%, #fff 1px, #fff 50%);
      &-inputModern{
        top: -7px!important;
      }
      @media (min-width:1260px) {
        display: none !important;
      }
      &-withDiscount{
        @media (max-width: 1610px) {
          display: flex !important;
        }
      }
      &-alwaysShown {
        display: flex !important;
      }
    }

    &-evenRow {
      background: linear-gradient(180deg, transparent 50%, #fff 1px, #fff 50%);
    }
  }
}

.clearInputX{
  position: absolute;
  color: var(--grey-6-color);
  margin: 12px 6px 0 -14px;
  cursor: pointer;
  height: 12px;
  user-select: none;
}

