@import 'variables.pcss';

.resetPassword{
    &-heading{
        margin-bottom: 20px;
    }
    
    &-info{
        color: var(--grey-1-color);
    }

    &-email{
        &-info{
            text-align: center;
        }
    }

    &-buttonsContainer{
        display: flex;
        justify-content: space-between;
        gap: 32px 0;

        & > * {
            min-width: 0;
        }

        @media (--breakpoint-mobile) {
            flex-flow: column;
        }
    }

    &-body{
        max-width: 300px;
    }
}

