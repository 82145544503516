.checkbox {
    user-select: none;
    display: flex;
    align-items: center;
    column-gap: 10px;
    margin:-1px;
    border: 1px solid transparent;

    &-disabled {
        color: var(--disabled-font-color);
    }

    &-container {
        color: white;
        display: flex;
        background-color: var(--primary-color);
        align-items: center;
        justify-content: center;
        border-radius: 5px;
        font-size: 11px;
        box-sizing: border-box;

        &-icon{
            width: inherit;
        }

        &-empty{
            background-color: white;
            border: 1px solid #a3a9b9;
            width: inherit;
            height: inherit;
        }
        
        &-circle{
            border-radius: 50%;
        }
    }

    &-pointer {
        cursor: pointer;
    }

    &:focus-visible{
        outline: none;
        border: 1px solid var(--primary-color);
        border-radius: 8px;
    }
}

