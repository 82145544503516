@import 'variables.pcss';

.header {
  background-color: var(--main-color);
  color: var(--white-color);
  padding: 15px;
  font-weight: 300;
  font-size: var(--title-font-size);
  box-shadow: 0 4px 4px rgba(0, 0, 0, .24), 0 0 4px rgba(0, 0, 0, .12);
  display: flex;
  justify-content: center;
  align-items: center;

  &-link {
    text-decoration: none;
    color: var(--white-color);
    display: flex;
    align-items: center;
  }

  &-logo-icon {
    margin-right: 5px;
  }
}

.layout {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: var(--dark-background-color);
}

.icon {
  width: 22px;
  height: 22px;
  padding-right: 10px;

    &-ie {
      width: 71px;
      height: 71px;
      color: #e0e0e0;
    }

    &-attention {
      position: relative;
      left: -12px;
      top: 24px;
      width: 23px;
      height: 23px;
      color: #d50000;
    }
}

.button {
  @mixin button-link-font;
  color: #fff;
  background-color: #2f80ed;
  width: 221px;
  height: 36px;
  letter-spacing: 0.5px;
  text-decoration: none;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.24), 0px 0px 2px rgba(0, 0, 0, 0.12);
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 24px;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 35px 0 24px 0;

  &-icon {
    padding: 6px;
  }

  &-warning {
    @mixin text-font;
    width: 443px;
    line-height: 24px;
    text-align: center;
    padding: 10px;
  }

  &-advice {
    @mixin title-font;
    width: 532px;
    line-height: 28px;
    text-align: center;
    padding-top: 38px;
  }

  &-info {
    @mixin text-font;
    color: #757575;
    width: 480px;
    padding-top: 56px;
    text-align: center;
  }

  &-footer-text {
    @mixin text-font;
    color: #757575;
    width: 480px;
    padding-top: 117px;
    text-align: center;
  }
}
