.passwordChecker{
    margin-top: 16px;
    display: flex;
    flex-flow: column;
    gap: 8px;

    &-icon{
        display: flex;
        align-items: center;
        gap: 16px;

        &-pass{
            width: 20px !important;
            height: 20px;
            color: var(--primary-color);
        }

        &-failed{
            width: 20px;
            aspect-ratio: 1;
            color: transparent;
            position: relative;

            &::before {
                content: "";
                width: 18.5px;
                top: 0.75px;
                left: 0.75px;
                position: absolute;
                aspect-ratio: 1;
                outline: 1.5px solid var(--grey-6-color);
                border-radius: 200%;
            }
        }
    }
}

