@import 'variables.pcss';


.required-mark {
  padding: 0px 0px 0px 4px;
  color: var(--error-color);
}

.input {
  position: relative;

  &-container {
    display: flex;
    align-items: center;
  }

  &-label {
    @mixin text-font;
    display: flex;
    flex-direction: column;

    &-text {
      white-space: nowrap;
      margin-bottom: 5px;
      display: flex;
      background-color: var(--light-background-color);
      font-size: 85%;
      position: absolute;
      top: -5px;
      left: 5px;
      padding: 0 3px;
    }
  }

  &-field {
    @mixin text-font;
    resize: none;
    padding: 9px;
    width: calc(100% - 20px);
    border: none;
    border-radius: 2px;
    overflow: hidden;
    background-color: var(--light-background-color);
    overflow-y: scroll;

    &:focus {
      outline: none;
      border-color: var(--primary-font-color);
    }

    &::placeholder {
      color: var(--disabled-font-color);
    }

    &-border {
      border: 1px solid var(--border-color);
      border-radius: 0;
      transition: border-color;
    }

    &-invalid {
      border-color: var(--error-border-color);
    }
  }

  &-error {
    height: 20px;
    padding: 3px 0;
    color: var(--error-color);
    font-size: 11px;
  }
}

.templateFooter {
  @mixin footer-text-font;
}
