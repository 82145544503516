@import 'variables.pcss';


.info-modal {
  flex-direction: row;
  justify-content: space-between;

  &-version-text {
    color: var(--secondary-font-color);
  }
}

.customer-mode {
  margin-left: 15px;
}

@keyframes zoomInOut {
  0%, 94%, 100% {
    transform: scale(1) rotate(0deg) translateX(0px);
  }
  95% {
    transform: scale(1.25) rotate(30deg) translateX(-5px);
  }
  96% {
    transform: scale(1.25) rotate(-30deg) translateX(5px);
  }
  97% {
    transform: scale(1.25) rotate(30deg) translateX(-5px);
  }
  98% {
    transform: scale(1.25) rotate(-30deg) translateX(5px);
  }
  99% {
    transform: scale(1.25) rotate(30deg) translateX(-5px);
  }
}


.header {
  background-color: var(--main-color);
  color: white;
  padding: 15px;
  font-weight: 300;
  font-size: var(--title-font-size);
  box-shadow: 0 4px 4px rgba(0, 0, 0, .24), 0 0 4px rgba(0, 0, 0, .12);
  display: flex;
  justify-content: space-between;
  align-items: center;
  grid-area: header;
  position: relative;

  &-smiley {
    align-self: center;

    &-container {
      position: relative;
      padding-top: 5px;
    }

    &-zoom {
      animation: zoomInOut 25s infinite ease-in-out;
      transform-origin: 50% 80%;
    }
  }
  &-smiley > svg {
    width: 24px;
    height: 24px;
    cursor: pointer;
  }

  &-notification{
    margin-right: 4px;
  }

  &-user{
    font-size: 14px;
    font-weight: 400;
    width: 19px;
    height: 19px;
    padding: 8px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--primary-color);
    color: white;
    position: relative;
    cursor: pointer;

    &-initials{
      cursor: inherit;

    }

    &-dropdown {
      top: 37px;
      position: relative;
      right: -3px !important;
    }
  }

  &-link {
    text-decoration: none;
    color: var(--white-color);
    display: flex;
    align-items: center;
    &-text {
      @media (max-width: 700px) {
        display:none;
      }
    }
  }

  &-action-button{
    white-space: nowrap;
    width: 100%;
    max-width: 265px;
    padding: 9.5px 20px;
    font-weight: 400;
  }

  &-help-button {
    width: 82px;
    padding: 8px;
    font-weight: 400;
    position: relative;
    overflow: visible;

    @media (max-width: 1100px) {
      width: 35px;
    }

    &-icon {
      @media (min-width: 1101px) {
        margin-right: 7px;
      }
    }

    &-text {
      @media (max-width: 1100px) {
        display:none;
      }
    }
  }

  &-title {
    font-weight: 500;
    font-size: 18px;
  }

  &-menu{
    display: flex;
    &-icon {
      margin-right: 20px;
      cursor: pointer;
      opacity: 0.54;
    }
  }

  &-left {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-grow: 2;
  }
   &-right {
    flex-grow: 2;
    display: flex;
    justify-content: flex-end;
    gap: 27px;
    align-items: center;
  }

  &-icons {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

  }

  &-info-icon {
    margin-left: 15px;
    opacity: 0.54;
  }

  &-logo-icon {
    margin-right: 20px;
  }

  &-dropdown {
    color: var(--white-color);
    position: relative;
    float: right;

    &-text {
      align-self: center;
      margin-left: 10px;

      &-time span {
        margin-right: 10px;
        margin-left: 10px;
      }
    }

    &-user-icon {
      margin-right: 15px;
      color: var(--main-color);
    }

    &-button {
      @media (--breakpoint-mobile) {
        display:none;
      }
      padding: 8.5px;
      width: 113px;
      font-weight: 400;

      &:focus {
        outline: none;
      }

      &-icon {
        margin: 0px 8px 0px 0px;
      }
    }

    &-button-small {
      width: 35px;
      padding: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      box-sizing: border-box;

      @media (--breakpoint-desktop) {
        display: none;
      }

      svg {
        width: 20px;
        height: 20px;
      }
    }

    &-list {
      width: 250px;
      position: absolute;
      right: 5px;
      margin-top: 16px;
      background-color: var(--light-background-color);
      display: flex;
      flex-direction: column;
      z-index: 9998;
      padding: 10px 0;
      border-radius: 8px;
      border: none;
      box-shadow: 0 2px 20px 0 rgba(0, 0, 0, .3);
      font-weight: 400;

      &-separator {
        border-bottom: 1px solid var(--border-color);
        margin: 0px 15px;
      }

      &-item {
        @mixin tableheader-font;
        display: flex;
        border: none;
        cursor: pointer;
        background-color: var(--light-background-color);
        margin: 2px 15px;
        padding: 11px 5px;
        text-align: left;
        border-radius: 8px;
        transition: all .3s;
        text-decoration: none;
        color: var(--secondary-color);

        &-icon {
          width: 35px !important;
          height: 20px;
        }

        &:focus {
          outline: none;
        }

        &:hover {
          background-color: var(--dark-background-color);
        }

        &-text {
          @mixin title-font;
          cursor: default;
          color: var(--secondary-color);

          &:hover {
            background-color: var(--light-background-color);
          }
        }

        &-active {
          color: var(--secondary-color);
          background-color: var(--primary-light-color);

          &:hover {
            background-color: var(--primary-light-color);
          }
        }
      }

      &-hidden {
        display: none;
      }
    }
    &-list:after {
      content: " ";
      position: absolute;
      top: -20px;  /* At the top of the tooltip */
      left: 90%;
      margin-left: -5px;
      border-width: 10px;
      border-style: solid;
      border-color:  transparent transparent white transparent;
    }

    &-rating {
      top: 37px;
      width: 327px;
      right: -22px;
      font-size: 14px;
      font-weight: 500;
      padding: 28px 32px 0 32px;
      color: var(--main-color);

      &-noRating {
        margin-bottom: 20px;
        text-align: center;
      }
      
      &-slider {
        display: grid;
        grid-template-areas: "sad sadmeh meh mehhappy happy";
        grid-template-columns: 55px 55px 55px 55px 55px;
        justify-content: space-evenly;
        position: relative;
        align-items: center;
        margin: 32px auto 0 auto;
        text-align: center;

        &-hidden{
          display: none;
        }

        &-active{
          position: absolute;
        }

        &-bar {
          width: 200px;
          position: absolute;
          border: 1px solid #F0F2F5;
          z-index: -1;
          top: calc(50% - 2px);
          left: 30px;
        }
        &-active > svg {
          width: 42px;
          height: 42px;
        }

        &-active > svg > circle {
          fill: var(--main-color);
        }
        &-sad {
          grid-area: sad;

          &-active {
            left: 2%;
            transition: all .5s;
          }
          &-active > svg > g > path {
            fill: #E8344D;
          }
        }
        &-sadMeh {
          grid-area: sadmeh;

          &-active {
            left: 22%;
            transition: all .5s;
          }
          &-active > svg > g >path {
            fill: #FD7E1F;
          }
        }
        &-meh {
          grid-area: meh;

          &-active {
            left: 42%;
            transition: all .5s;
          }
          &-active > svg > g > path {
            fill: #FFCE2B;
          }
        }
        &-mehHappy {
          grid-area: mehhappy;

          &-active {
            left: 62%;
            transition: all .5s;
          }
          &-active > svg > g > path {
            fill: #ACD91A;
          }
        }
        &-happy {
          grid-area: happy;

          &-active {
            left: 82%;
            transition: all .5s;

          }
          &-active > svg > g > path {
            fill: #4DCC57;
          }
        }
      }
      &-slider > div > svg {
        border-radius: 50%;
        cursor: pointer;
      }

      &-satisfaction {
        display: flex;
        justify-content: space-between;
        white-space: break-spaces;
        text-align: center;
        width: 267px;
        margin: 5px auto 30px auto;
        color: #A3A9B9;
        font-size: 11px;
      }
      &-thanks {
        margin-bottom: 10px;
        font-size: 18px;
      }
      &-improvements {
        margin-bottom: 15px;

        &-text > label > textarea {
          height: 84px;
        }

        &-text > label > textarea::placeholder {
          font-size: 11px;
        }
      }
      &-submit {
        width: fit-content;
        padding: 10px;
        margin-top: 21px;
        margin-bottom: 32px;
        font-weight: 400;
        min-width: 237px;

        &-text {
          font-size: 16px;
          margin-bottom: 16px;
        }

        &-picture {
          width: fit-content;
          margin: auto;
        }

        &-button {
          width: fit-content;
          padding: 13px 19px;
          margin-top: 16px;
        }
      }
    }
    &-webinar { 
        text-align: right;
    }

    &-openHours { 
      width: 120px;
    }

    &-help {
      color: var(--grey-1-color);
      width: 574px;
      box-sizing: border-box;
      padding: 16px;
      right: -14px;

      @media (max-width: 1100px) {
        right: -37px;
      }

      &-content{
        display: flex;
        flex-flow: column;
        gap: 16px;
        max-height: calc(100vh - 120px);
        overflow-y: auto;
      }

      &-button { 
        display: flex;
        text-align: left;
        font-size: 16px;
      }

      &-wrapper {
        color: var(--white-color);
        position: relative;
        float: right;
      }

      &-accordion { 
        border-bottom: none;

        &-header{
          color: #429488;
          font-size: 16px;
          font-weight: 500;
          padding-left: 0;
        }

        &-items { 
          display: flex;
          margin-bottom: 16px;

          &-friday { 
            display: flex;
          }

          &-current{
            font-weight: 600;
          }
        }
        &-container {
          display: flex;
          align-items: baseline; 
          justify-content: space-between;
        }
      }

      &-listItem {
        display: flex;
        align-items: center;
        text-align: left;
        white-space: normal;

        &-contact {
          line-height: 30px;
          color: var(--secondary-color);
        }

        &-phone {
          display: flex;
          gap: 14px;
        }
      }

      &-container {
        padding: 16px;
        display: flex;
        flex-flow: column;
        gap: 19px;
        font-size: 16px;
        font-weight: 400;
        border-radius: 8px;

        &:hover {
          box-shadow: 0px 8px 24px rgba(79, 99, 116, 0.25);
        }
        
        &-withSvg {
          flex-flow: row;
          align-items: center;
          justify-content: space-between;

          &-content{
            display: flex;
            gap: 12px;
            flex-flow: column;
          }
        }
        &-hidden { 
          display: none;
        }
      }

      &-text {
        display: flex;
        margin-left: 10px;
      }
      &-text > * {
        align-self: center;
      }

      &-chat {
        width: 69px;
        padding: 4px;
        margin-left: 10px;
        color: var(--main-color);
        font-weight: 400;
        font-size: 16px;
        line-height: 30px;
        background-color: #f0f2f5;
      }

      &-center {
        padding: 4px 10px;
        margin-left: 10px;
        color: var(--main-color);
        font-weight: 400;
        background-color: #F0F2F5;
        border-radius: 8px;
        text-decoration: none;
        font-size: 16px;
      }
      &-center:hover {
        box-shadow: 0 0 8px rgba(0,0,0,0.75);
      }

      &-separator {
        border-bottom: 1px solid var(--border-color);
        margin: 16px 0;
      }

      &-download {
        width: fit-content;
        text-transform: none;
        font-weight: 400;
        font-size: 14px;
        color: var(--secondary-color);
        padding: 6px 8px;
        display: flex;
        gap: 8px;
        align-items: center;
        justify-content: center;
      }

      &-aboutUs {
        padding: 0;
        display: flex;
        gap: 16px;
      }
    }

    &-options {
      right: 0;
    }
    &-options:after {
      left: 75%;

      @media (max-width: 900px) {
        left: 91%;
      }
    }
  }

  @media (--breakpoint-mobile) {
    width: calc(100vw - 30px);
  }
}

.customerMode{
  padding: 5px 15px 15px 15px;
}
.switchMargin{
  margin-right: 20px;
}
@media print {
  .header {
    display: none;
  }
}
