.listItem {
  position: relative;
  list-style: none;
  margin: 0;

  &-button {
    height: 100%;
    width: 100%;
    padding: 5px;
    text-align: left;
    background: transparent;
    border: none;
    cursor: pointer;
    transition: all .2s ease;

    &:hover {
      background-color: var(--additional-color);
      color: white;
    }
  }

  &-selected {
    background: var(--additional-color);
    color: white;
  }
}
