@import 'variables.pcss';

.button {
  @mixin button-font;
  display: block;
  padding: 10px 0;
  color: var(--white-color);
  border: none;
  border-radius: 2px;
  overflow: hidden;
  transition: all .3s;
  cursor: pointer;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.24), 0px 0px 2px rgba(0, 0, 0, 0.12);
  @media (min-width:1251px) {
    width: 176px;
  }
  @media (min-width:901px) and (max-width:1250px) {
    width: 150px;
  }
  @media (max-width:900px) {
    width: 150px;
  }
  text-align: center;
  text-decoration: none;

  &-small {
    width: 120px;
  }

  &-extra-small {
    padding: 10px 15px;
    width: auto;
  }

  &-icon {
    margin-right: 13px;
  }

  &-add {
    background-color: var(--add-button-color);

    &:hover {
      filter: brightness(1.08);
    }

    &[disabled] {
      background-color: var(--add-button-disabled-color);
      cursor: not-allowed;
    }
  }

  &-add-inline {
    display: inline;
    margin-left: 10px;
    background-color: var(--add-button-color);

    &:hover {
      filter: brightness(1.08);
    }

    &[disabled] {
      background-color: var(--add-button-disabled-color);
      cursor: not-allowed;
    }
  }

  &-white {
    color: #111;
    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.24), 0px 0px 0px rgba(0, 0, 0, 0.12);
    margin-right: 3px;
    background-color: var(--white-color);

    &:hover {
      background-color: var(--edit-button-color);
    }

    &[disabled] {
      background-color: var(--edit-button-color);
      cursor: not-allowed;
    }
  }

  &-remove {
    background-color: var(--remove-button-color);

    &:hover {
      filter: brightness(1.15);
    }

    &[disabled] {
      filter: brightness(1.06);
      cursor: not-allowed;

      &:hover {
        filter: brightness(1.10);
      }
    }
  }

  &-export {
    background-color: var(--export-button-color);

    &:hover {
      filter: brightness(1.15);
    }

    &[disabled] {
      filter: brightness(0.95);
      cursor: not-allowed;

      &:hover {
        filter: brightness(0.90);
      }
    }
  }

  &-info {
    background-color: var(--message-information-color);

    &:hover {
      filter: brightness(1.15);
    }

    &[disabled] {
      filter: brightness(1.10);
      cursor: not-allowed;

      &:hover {
        filter: brightness(1.05);
      }
    }
  }
}

.buttonModern{
  @mixin button-font-modern;
  display: inline-block;
  padding: 13px 19px;
  color: white;
  border: none;
  border-radius: 8px;
  transition: all .3s;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  white-space: pre;
  justify-content: center;

  &-withIcon{
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &-small{
    padding: 8px 12px;
    font-weight: 400;
  }

  &:hover{
    box-shadow: 0 0 8px rgba(0,0,0,0.75);
  }

  &:focus-visible{
    outline: none;
    box-shadow: 0 0 8px rgba(0,0,0,0.75);
  }

  &-add {
    background-color: var(--primary-color);
    color:white;

    &[disabled] {
      background-color: var(--add-button-disabled-color);
      cursor: not-allowed;
    }
  }

  &-export {
    background-color: var(--export-button-color);

    &:hover {
      filter: brightness(1.15);
    }

    &[disabled] {
      filter: brightness(1.08);
      cursor: not-allowed;

      &:hover {
        filter: brightness(1.12);
      }
    }
  }

  &-cancel {
    background-color: var(--grey-9-color);
    color: var(--secondary-color);
    min-width: 110px;

    &:hover {
      box-shadow: 0 0 8px rgba(0,0,0,0.75);
    }

    &[disabled] {
      filter: brightness(0.8);
      cursor: not-allowed;
    }
  }

  &-highlight {
    background-color: var(--highlight-color);
    color: var(--secondary-color);
    min-width: 110px;

    &:hover {
      box-shadow: 0 0 8px rgba(0,0,0,0.75);
    }

    &[disabled] {
      filter: brightness(0.8);
      cursor: not-allowed;
    }
  }

  &-delete {
    color: var(--message-error-color);
    background-color: var(--message-error-light-color);

    &[disabled] {
      filter: brightness(0.8);
      cursor: not-allowed;

      &:hover {
        filter: brightness(0.7);
      }
    }
  }

  &-select{
    color: var(--secondary-color);
    background-color: var(--grey-9-color);
    font-weight: 400;
    padding: 10px 15px;
    display: flex;
    gap: 8px;
    align-items: center;
    max-width: 100%;
    justify-content: center;

    &:hover {
      box-shadow: none;
      filter: brightness(0.92);
    }

    &[disabled] {
      filter: brightness(0.96);
      cursor: not-allowed;

      &:hover {
        filter: brightness(0.96);
      }
    }
  }

  &-config {
    background-color: white;
    color: #3e5a6c;

    &[disabled] {
      background-color: var(--add-button-disabled-color);
      cursor: not-allowed;
    }
  }

  &-size{
    &-small{
      font-size: 14px;
      line-height: 16px;
      padding: 6px 8px;
      min-height: 16px;
      font-weight: 400;
    }
    &-medium{
      font-weight: 400;
      font-size: 16px;
      padding: 8px 12px;
      min-height: 19px;
    }
    &-default{
      font-size: 16px;
    }
    &-medium{
      padding: 8px 12px;
      font-size: 16px;
      font-weight: 400;
    }
  }

  &-withIcon {
    display: flex;
    gap: 8px;
  }
}

.text-button {
  background-color: transparent;
  border: none;
  transition: all .3s;
  cursor: pointer;
  padding: 0;
  margin: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: left;
  text-decoration: none;

  &-right {
    text-align: right;
  }

  &-center {
    text-align: center;
  }

  &:focus {
    outline: none;
  }
}

.image-button {
  border: none;
  cursor: pointer;
  padding: 0;
  margin: 0;
  outline: none;
}

.link {
  text-decoration: none;
}
