@import 'variables.pcss';

.mileage {

  &-content {
    justify-content: space-between;
    align-items: stretch;
    margin-bottom: 32px;
    display: block;
    @media (--breakpoint-desktop) {
      display: flex;
    }
  }

  &-fixed-width {
    width: 265px;

    @media (--breakpoint-mobile-l) {
      width: 100%;
    }
  }

  &-visible-overflow {
      @media (--breakpoint-desktop) {
        overflow: visible;
      }
  }

  &-margin-bottom {
    margin-bottom: 32px;
  }

  &-right-element {

    table {
      max-height: 175px;
    }

    margin-top: 32px;
    
    @media (--breakpoint-desktop) {
      margin-left: 64px;
      margin-top: 0px;
    }
  }

  &-icon {
    cursor: pointer;
    width: 20px;
    height: 20px;

    &-action {
      float: right;
      color: var(--action-button-color);
      padding: 5px 3px;
      margin: 3px;

      &:hover {
        color: #c9c9c9;
      }
    }
  }
}
