@import 'variables.pcss';


.required-mark {
    padding: 0px 0px 0px 4px;
    color: var(--error-color);
}

.input {
    position: relative;

    &-container {
        display: flex;
        align-items: center;
    }

    &-label {
        @mixin text-font;
        display: flex;
        flex-direction: column;

        &-text {
            white-space: nowrap;
            margin-bottom: 5px;
            display: flex;
            background-color: var(--light-background-color);
            font-size: 12px;
            position: absolute;
            top: -15px;
        }

    }

    &-field {
        @mixin text-font;
        resize: none;
        padding: 10px;
        box-sizing: border-box;
        border: none;
        border-radius: 8px;
        overflow: hidden;
        background-color: var(--light-background-color);
        overflow-y: auto;

        &:focus {
            outline: none;
            border: 1px solid var(--primary-color);
        }

        &::placeholder {
            color: var(--disabled-font-color);
        }

        &-border {
            border: 1px solid var(--border-color);
            border-radius: 8px;
            transition: border-color;
        }

        &-invalid {
            border-color: var(--error-border-color);
            &:focus {
                border-color: var(--error-border-color);
            }
        }
    }


    &-error {
        height: 20px;
        padding: 3px 0;
        color: var(--error-color);
        font-size: 11px;
    }
}

.templateFooter {
    @mixin footer-text-font;
}

.textAreaCopy{
    position: absolute;
    visibility: hidden;
    white-space: pre-wrap;
    word-break: break-word;
    width: 100%;
}
