@define-mixin title-font {
  color: var(--primary-font-color);
  font-family: "Roboto", sans-serif;
  font-size: var(--title-font-size);
  font-weight: 500;
}

@define-mixin sub-title-font {
  color: var(--primary-font-color);
  font-family: "Roboto", sans-serif;
  font-size: var(--sub-title-font-size);
  font-weight: 500;
}

@define-mixin subheader-font {
  font-family: "Roboto", sans-serif;
  font-size: var(--subheader-font-size);
  font-weight: 500;
}

@define-mixin button-font {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  text-transform: uppercase;

  @media (min-width:1251px) {
    font-size: var(--button-font-size);
  }
  @media (min-width:901px) and (max-width:1250px) {
    font-size: var(--button-small-font-size);
  }
  @media (max-width:900px) {
    font-size: var(--button-small-font-size);
  }
}

@define-mixin button-font-modern {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  text-transform: none;

  @media (min-width:1251px) {
    font-size: var(--button-font-size);
  }
  @media (min-width:901px) and (max-width:1250px) {
    font-size: var(--button-small-font-size);
  }
  @media (max-width:900px) {
    font-size: var(--button-small-font-size);
  }
}

@define-mixin button-link-font {
  font-family: "Roboto";
  font-size: var(--button-font-size);
  font-weight: 500;
}

@define-mixin tableheader-font {
  color: var(--primary-font-color);
  font-family: "Roboto", sans-serif;
  font-size: var(--tableheader-font-size);
  font-weight: 500;
}

@define-mixin sidebar-font {
  color: var(--primary-font-color);
  font-family: "Roboto", sans-serif;
  @media (min-width:1251px) {
    font-size: var(--sidebar-font-size);
    font-weight: 400;
  }
  @media (min-width:901px) and (max-width:1250px) {
    font-size: var(--medium-sidebar-font-size);
    font-weight: 400;
  }
  @media (max-width:900px) {
    font-size: var(--small-sidebar-font-size);
    font-weight: 400;
  }
}

@define-mixin tabletext-font {
  color: var(--primary-font-color);
  font-family: "Roboto", sans-serif;
  @media (min-width:1251px) {
    font-size: var(--tabletext-font-size);
  }
  @media (min-width:901px) and (max-width:1250px) {
    font-size: var(--medium-tabletext-font-size);
  }
  @media (max-width:900px) {
    font-size: var(--small-tabletext-font-size);
  }
}

@define-mixin label-font {
  color: var(--secondary-font-color);
  font-family: "Roboto", sans-serif;
  font-size: var(--label-font-size);
  font-weight: 500;
}

@define-mixin text-font {
  color: var(--primary-font-color);
  font-family: "Roboto", sans-serif;
  font-size: var(--text-font-size);
}

@define-mixin footer-text-font {
  color: var(--primary-font-color);
  font-family: "Roboto", sans-serif;
  font-size: var(--footer-text-font-size);
}

@define-mixin link-font {
  color: var(--primary-color);
  font-family: "Roboto", sans-serif;
  font-size: var(--link-font-size);
  font-weight: 500;
  text-decoration: none;

  &:hover {
    color: #397f75;
  }
}

@define-mixin link-color {
  color: var(--primary-color);

  &:hover {
    color: #397f75;
    text-decoration: underline !important;
  }
}

@define-mixin link-modern {
  @mixin link-color;
  font-size: var(--link-font-size);
  font-weight: 500;
  text-decoration: none;
  cursor: pointer;
}

@define-mixin no-select {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
}

@define-mixin error-font {
  color: var(--error-color);
  font-family: "Roboto", sans-serif;
  font-size: var(--error-font-size);
}

@define-mixin list-background {
  background: var(--light-background-color);
  &:nth-child(2n+1) {
      background: var(--dark-table-background-color);
  }
}

@define-mixin tooltip-dark{
  div:nth-child(2) > div{
    background-color: var(--grey-2-color);
    color: var(--main-white, #FFF);
    border: unset;
    text-align: center;
    border-radius: 4px;
    max-width: 300px;
  }
}

@define-mixin tooltip-light{
  color: var(--grey-2-color) !important;
  background-color: var(--white-color) !important;
  border: 1px solid var(--grey-2-color) !important;
  z-index: 11;
}

@custom-media --breakpoint-invoice-header (width <= 1500px);
@custom-media --breakpoint-invoice-header-panels (width <= 920px);

@custom-media --breakpoint-invoice-items-s (width <= 1090px);
@custom-media --breakpoint-invoice-items-m (width <= 1260px);
@custom-media --breakpoint-invoice-items-l (width <= 1610px);

@custom-media --breakpoint-mobile (width <= 900px);
@custom-media --breakpoint-desktop (width > 900px);
@custom-media --breakpoint-tablet (width <= 768px);

@custom-media --breakpoint-mobile-l (width <= 440px);