@import 'normalize.css';

:root {

  /*New modern colors*/

  --primary-color: #429488;
  --primary-light-color: #AAD9D0;
  --primary-lighter-color: #E3EFED;
  --primary-lighter-transparent-color: rgba(66, 148, 136, 0.15);
  --secondary-color: #4F6374;
  --highlight-color: #FFB03A;

  --grey-1-color: #26282D;
  --grey-2-color: #454953;
  --grey-3-color: #636977;
  --grey-4-color: #6F6F6F;
  --grey-5-color: #A8A8A8;
  --grey-6-color: #A3A9B9;
  --grey-7-color: #E0E0E0;
  --grey-8-color: #E3E6E9;
  --grey-9-color: #F0F2F5;
  --grey-10-color: #FAFAFD;

  --white-color: #FFFFFF;

  --message-warning-color: #EE762F;
  --message-warning-light-color: #FCEDE4;
  --message-error-color: #E52717;
  --message-error-light-color: #FFEAE9;
  --message-information-color: #49A0FC;
  --message-information-light-color: #DDEDFF;
  --message-success-color: #5CD465;
  --message-success-light-color: #E4FFE6;

  --status-credit:#A425FF;
  --status-credit-light:#F6E9FF;

  --mark-red-color: #E52717;
  --mark-orange-color: #EE762F;
  --mark-yellow-color: #FBD530;
  --mark-green-color: #5CD465;
  --mark-turquoise-color: #55D2B7;
  --mark-blue-light-color: #49A0FC;
  --mark-blue-color: #3863FF;
  --mark-purple-color: #A425FF;

  --price-positiv-color: #008000;
  --price-negativ-color: #FF0000;

  --main-color: #3e5a6c;
  --additional-color: #788e9b;
  --select-color: #efefef;
  --error-color: #E52717;

  --dark-background-color: #eeeeee;
  --light-background-color: #fff;

  --primary-font-color: var(--grey-1-color);
  --secondary-font-color: var(--grey-4-color);
  --disabled-font-color: var(--grey-5-color);

  --disabled-background-color: #F3F3F3;

  --headline-font-size: 24px;
  --title-font-size: 20px;
  --sub-title-font-size: 17px;
  --subheader-font-size: 15px;
  --button-font-size: 14px;
  --button-small-font-size: 12px;
  --tableheader-font-size: 13px;
  --tabletext-font-size: 13px;
  --medium-tabletext-font-size: 12px;
  --small-tabletext-font-size: 11px;
  --label-font-size: 13px;
  --text-font-size: 13px;
  --link-font-size: 13px;
  --error-font-size: 12px;
  --footer-text-font-size: 10px;
  --sidebar-font-size: 14px;
  --medium-sidebar-font-size: 12px;
  --small-sidebar-font-size: 10px;

  --add-button-color: #009688;
  --add-button-disabled-color: #85aaa6;
  --edit-button-color: #dbe2e1;
  --remove-button-color: #62737e;
  --export-button-color: #a4a4a4;
  --action-button-color: #a2a2a2;

  --border-color: #dddddd;
  --error-border-color: #A82012;

  --dark-table-background-color: #f5f5f5;

  --overlay-color: rgba(0, 0, 0, 0.2);

  --draft-color: #9e9e9e;
  --preview-color: #3f596b;
  --open-color: #3f596b;
  --paid-color: #53ae54;
  --due-color: #f0463c;

  --spacing-xs: 4px;
  --spacing-s: 8px;
  --spacing-m: 16px;
  --spacing-l: 32px;
  --spacing-xl: 64px;
}

/* Thin */
@font-face {
  font-family: 'Roboto';
  src: url('/assets/fonts/Roboto/Thin.ttf') format('truetype');
  font-style: normal;
  font-weight: 100;
}

/* Light */
@font-face {
  font-family: 'Roboto';
  src: url('/assets/fonts/Roboto/Light.ttf') format('truetype');
  font-style: normal;
  font-weight: 300;
}

/* Normal */
@font-face {
  font-family: 'Roboto';
  src: url('/assets/fonts/Roboto/Regular.ttf') format('truetype');
  font-style: normal;    
  font-weight: 400;
}

/* Medium */
@font-face {
  font-family: 'Roboto';
  src: url('/assets/fonts/Roboto/Medium.ttf') format('truetype');
  font-style: normal;
  font-weight: 500;
}

/* Bold */
@font-face {
  font-family: 'Roboto';
  src: url('/assets/fonts/Roboto/Bold.ttf') format('truetype');
  font-style: normal;    
  font-weight: 700;
}

/* Black */
@font-face {
  font-family: 'Roboto';
  src: url('/assets/fonts/Roboto/Black.ttf') format('truetype');
  font-style: normal;
  font-weight: 900;
}

/* Ubuntu for our logo */
@font-face {
  font-family: 'Ubuntu';
  src: url('/assets/fonts/Ubuntu-Bold.ttf') format('truetype');
  font-style: normal;
  font-weight: 700;
}

html,
body {
  color: var(--primary-font-color);
  font-family: 'Roboto', sans-serif;
  font-size: var(--text-font-size);
  position: relative;
  overscroll-behavior-x: none; /* Disable swipe navigation */
}

@media print {
  table {
    display: block !important;
  }
}

/* Scrollbar */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-track {
  background: var(--grey-8-color);
  margin-top: 2px;
  border-radius: 20px;
}

::-webkit-scrollbar-thumb {
  background: var(--grey-5-color);
  border-radius: 20px;
  border: 0px none #ffffff00;
}

::-webkit-scrollbar-thumb:hover {
  background: var(--grey-4-color);
}
