@import 'variables.pcss';

.notification {
  &-icon {
    margin-top: 5px;
    cursor: pointer;

    &-container {
      position: relative;
      cursor: pointer;
    }

    &-container > svg {
      width: 24px !important;
      height: 24px;
    }

    &-circle {
      position: absolute;
      right: -10px;
      top: -5px;
      font-weight: 700;
      border-radius: 1000px;
      background-color: var(--mark-red-color);
      font-size: 9pt;
      width: 20px;
      height: 20px;
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &-modal {
    z-index: 500;
    max-height: calc(100vh - 150px);
    overflow-y: auto;
    position: absolute;
    top: 22px;
    right: -37px;
    width: 550px;
    max-width: calc(100vw - 200px);

    @media (--breakpoint-mobile) {
      position: fixed;
      max-width: unset;
      width: 98vw;
      margin: 0 1vw;
      top: 70px;
      left: 0;
    }

    min-height: 100px;
    background-color: var(--light-background-color);
    box-shadow: 0 2px 20px 0 rgba(0, 0, 0, .3);
    border-radius: 8px;
    color: var(--main-color);

    &-outer {
      @media (max-width: 1100px) {
        position: relative;
      }
      @media (min-width: 1101px) {
        position: relative;
      }
    }
    &-outer:after {
      content: " ";
      position: absolute;
      top: 2px;  /* At the top of the tooltip */
      left: 15%;
      margin-left: -5px;
      border-width: 10px;
      border-style: solid;
      border-color:  transparent transparent white transparent;
      z-index: 600;
    }
  }

  &-header {
    height: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 16px;

    &-bell{
      height: 20px;
      width: 20px !important;
      margin-right: 10px;
    }
    &-text {
      font-size: 10pt;
      font-weight: bold;
      display: flex;
      align-items: center;
    }
  }

  &-list {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 8px;

    &-container{
      max-height: 350px;
      overflow-y: auto;
      transition: all 0.3s ease;
    }
  }

  &-element {
    position: relative;
    border-radius: 8px;
    cursor: pointer;
    margin-bottom: 16px;
    font-size: 16px;
    padding-left: 10px;
    padding-right: 30px;

    &-description {
      padding: 5px 5px 5px 0;
      white-space: break-spaces;
      text-overflow: ellipsis;
      overflow: hidden;
      line-height: 20px;
    }
  }

  &-showMore{
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: 12px;
    gap: 8px;
    padding: 8px 0 16px 0;
    color: var(--main-color);

    &-icon{
      height: 14px;
    }
  }
}

@media (--breakpoint-mobile) {
  .notification{
    &-list{
      &-container{
        max-height: unset;
      }
    }
  }
}