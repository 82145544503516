@import "variables.pcss";

.content {
  display: flex;
  position: relative;
  flex-direction: column;
  border-radius: 2px;
  max-height: 736px;
  min-width: 950px;
  max-width: 70%;
  background-color: var(--light-background-color);
  outline: none;
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2),
    0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

@media (max-width: 768px) {
  .content {
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--overlay-color);
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;

  &-transparent {
    background-color: transparent;
  }
}

.header {
  padding: 0px;
  padding-bottom: 24px;

  &-title {
    @mixin title-font;
    margin: 0;
    text-align: left;
    font-size: 22px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.actions {
  padding: 10px;
  display: flex;
  justify-content: space-between;

  &-modern {
    padding: 0;
  }
}

.wrapper {
  display: flex;
  justify-content: center;
}

.container {
  width: 100%;
  overflow-y: auto;
  margin-bottom: 35px;

  &-modern {
    width: 100%;
    margin: 8px 0px 32px 0px;
    max-width: 450px;
  }
}

.form {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  padding: 40px 0;

  &-input {
    width: 320px;
    height: 75px;
  }

  &-checkbox {
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
    width: 320px;

    &-icon {
      cursor: pointer;
      width: 20px;
      height: 20px;
      z-index: 2;
      margin-right: 10px;

      &-action {
        float: right;
        color: #999999;
      }

      &:hover {
        color: #333;
      }
    }

    &-text {
      line-height: 20px;
    }
  }

  &-dropdown {
    width: 320px;
    z-index: 3;
  }
}

.footer {
  min-height: 35px;
  padding: 10px 24px 24px;
  display: flex;
  justify-content: center;
  button {
    margin-left: 10px;
  }

  &-modern {
    display: flex;
    gap: 32px;

    &-margin{
      margin-top: 32px;
    }

    &-buttonIconRightPadding {
      padding-right: 10px;
    }
  }

  @media (--breakpoint-mobile) {
    &-modern {
      display: grid;
      gap: 16px;

      &-buttonIconRightPadding {
        padding-right: 10px;
      }
    }
  }
}

.headerButtonRight {
  display: flex;
  justify-content: flex-end;
  padding: 15px 15px;
  height: 36px;
}

.searchBlock {
  display: flex;
  padding: 15px;
  width: 300px;

  @media (--breakpoint-mobile) {
    flex-direction: column;
    width: 250px;
  }

  &-input {
    max-width: 175px;
  }
}

.modal {
  &-close-icon {
    cursor: pointer;
    position: absolute;
    right: 24px;
    top: 24px;
  }

  &-body {
    padding: 24px;
  }
}
