@import 'variables.pcss';

.signup{
  @custom-media --breakpoint-signup (width <= 750px);

  &-footer{
    position: relative;
    min-width: 187px;
    margin: 42px 0 48px 0;
    padding: 32px;
    background-color: white;
    color: var(--grey-1-color);
    text-align: center;
    display: flex;
    flex-flow: column;
    gap: 32px;
    font-weight: 400;
    font-size: 16px;

    @media (--breakpoint-mobile) {
      width: calc(100% - 104px);
      border-radius: 8px;
      margin: 20px;
    }

    &-questions{
      display: flex;
      justify-content: center;
      gap: 11px;
      white-space: nowrap;
      flex-wrap: wrap;

      &-title{
        font-size: 18px;
        font-weight: 500;
      }
    }

    &-image{
      width: 80px;
      aspect-ratio: 1;
      border-radius: 400%;

      &-container{
        display: flex;
        flex-wrap: wrap;
        gap: max(16px, min(48px, 2vw));
        justify-content: center;
      }
    }

    &-contact{
      display: flex;
      justify-content: center;
      gap: 8px 30px;
      flex-wrap: wrap;

      &-item{
        display: flex;
        gap: 16px;
        width: 230px;
      }
    }
  }

  &-header{
    &-container{
      text-align: center;
      font-weight: 400;
      margin-bottom: 32px;

      &-text{
        white-space: nowrap;
        @media (--breakpoint-signup) {
          white-space: pre-wrap;
        }
      }
    }
    &-text{
      margin-top: 8px;
      font-size: 12px;
    }
  }

  &-socialPage{
    position: relative;
    margin-top: 175px;

    @media (--breakpoint-signup) {
      display: none;
    }
  }

  &-checkbox {
    white-space: nowrap;
    width: fit-content;
    @media (--breakpoint-signup) {
      white-space: pre-wrap;
    }

    &-smallBusiness {
      white-space: pre-wrap;
    }

    &-container{
      display: flex;
      flex-direction: column;
      gap: 16px;
    }
  }
}