@import 'variables.pcss';

.containerCard{
  padding: 16px;
  border-radius: 8px;
  overflow: hidden;
  cursor: pointer;
  border-radius: 8px;

  &-success {
    &-background {
      background: var(--message-success-light-color);
    }
    &-icon {
      color: var(--message-success-color);
    }
  }

  &-warning {
    &-background {
      background: var(--message-warning-light-color);
    }
    &-icon {
      color: var(--message-warning-color);
    }
  }

  &-error {
    &-background {
      background: var(--message-error-light-color);
    }
    &-icon {
      color: var(--message-error-color);
    }
  }

  &-processed{
    background: var(--grey-10-color);
  }

  &-data{
    display: grid;
    grid-template-columns: 32px auto 45px;
    height: 100%;
    width: 100%;
    vertical-align: top;

    &-icon{
      padding-right: 16px;
      align-self: center;

      &-processed{
        color: var(--secondary-color);
      }
    }

    &-text{
      align-items: center;
      font-size: 14px;
      line-height: 16px;
      font-weight: normal;
      white-space: pre-wrap;
    }

    &-timestamp{
      color: var(--grey-4-color);
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      align-self: center;
    }
  }

  &-element{
    align-items: center;
    vertical-align: middle;
    white-space: pre-wrap;
    font-size: 14px;
    line-height: 16px;
    color: #26282D;
    font-weight: normal;
    display: flex;
    padding: 10px 0 0 15px;
    line-height: 20px;
  }
}
