@import 'variables.pcss';

.date-picker {

  &-label {
    position: relative;
    display: flex;
    @mixin text-font;

    &-column {
      flex-direction: column;
    }

    &-text {
      white-space: nowrap;
      margin-right: 5px;
      margin-bottom: 5px;
      display: flex;
      align-items: center;
      background-color: var(--light-background-color);
      font-size: 85%;
      position: absolute;
      top: -39px;
      left: 5px;
      padding: 0 3px;
    }
  }

  &-field {
    @mixin text-font;
    border: 1px solid rgba(0, 0, 0, 0.12);
    border-radius: 2px;
    box-sizing: border-box;
    padding: 9px;
    width: 100%;

    &:focus {
      outline: none;
      border-color: #ebebeb;
    }

    &-error {
      border: 1px solid var(--error-border-color);
    }
  }

  &-required-mark {
    padding: 0px 0px 0px 4px;
    color: var(--error-color);
  }

  &-error {
    @mixin error-font;
    margin-top: 4px;
  }
}
