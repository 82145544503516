@import 'variables.pcss';

.content {
  width: 100%;
  height: 250px;
  min-width: 500px;
  font-size: 12px;

  &-blocks {
    display: flex;
  }

  &-blockInDetailView {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    padding: 5px 15px;
    width: calc(50% - 50px);
  }

  &-blockInTableModalView {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    padding: 5px 15px;
  }
}

.name {
  @mixin title-font;
  color: #1F1D1D;
  padding: 10px 10px 0 10px;
  display: flex;

  &-text {
    display: flex;
    flex-direction: column;

    &-id {
      font-size: 10px;
    }

    &-address {
      font-size: 13px;
      color: #aaa;
    }

    &-full-name {
      font-size: 18px;
    }
  }

  &-icon {
    height: 50px;
    width: 50px;
    padding-right: 9px;
    color: #3E596C;
  }
}

.data {
  display: flex;

  &-icon {
    color: #CDCCCC;
    height: 14px;
    width: 14px;
    font-size: 14px;
    padding-right: 9px;
  }

  &-text {
    font-size: 12px;
    color: #212121;
    text-overflow: ellipsis;
    overflow: hidden;

    &-one-line {
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      line-height: 20px;
    }

    &-two-line {
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      line-height: 20px;
    }

    &-three-line {
      overflow: hidden;
      display: -webkit-box;
      height: 3.30em;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
    }
  }
}

