.overscroll{
  color: red;
  height: 100vh;
  overflow: auto;

  &-header{
    position: absolute;
    bottom: 0;
    width: 100%;

    &-centered{
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &-container{
      position: relative;
    }
  }
}