@import "variables.pcss";

.access{
    height: 100%;
    width: 100%;
}

.layout{
    width: 100%;
    height: 100%;

    &-container{
        display: flex;
        justify-content: center;
        padding: 64px 10px 0 10px;
        padding-top: 2vh;
        gap: 96px;

        .layout-content{
            height: auto;
        }
    }

    &-content{
        position: relative;
        height: fit-content;
        display: flex;
        align-items: center;
        flex-flow: column;
        justify-content: center;
        /* OR: padding-top: min(20%, 15vh) */
        height: 95vh;

        &-header{
            &-logo {
                font-size: min(max(8vw,20px),50px);
                margin-bottom: 65px;
            }
        }
        &-body{
            min-width: 180px;
            max-width: 256px;
            width: calc(100vw - 40px);
            display: flex;
            flex-flow: column;
            gap: 32px;

            @define-mixin message {
                font-weight: 400;
                font-size: 16px;
                color: var(--secondary-color);
            }

            &-message{
                @mixin message;

                &-header{
                    @mixin message;
                    font-size: 24px;
                    text-align: center;
                    font-weight: 500;
                }
            }

            &-bottomText{
                font-size: 14px;
                color: var(--grey-1-color);
                white-space: pre-wrap;
            }
        }
    }

    &-link{
        @mixin link-modern;
    }
}