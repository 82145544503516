@import 'variables.pcss';

.optionPickerContainer {
  background-color: var(--add-button-color);
  border-radius:360px;
  position: relative;
  
  .slider {
    position: absolute;
    background-color: #ffffff45;
    width: 46%;
    height: 75%;
    top: 50%;
    transform: translateY(-50%);
    border-radius:360px;
    left: 2%;
    transition: all 0.3s ease-in-out;
  }

  .optionsContainer{
    height: 100%;
    width: 100%;
    display: flex;

    .option{
      color: white;
      z-index: 2;
      font-weight: 500;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: space-around;
      align-items: center;
      cursor: pointer;
      user-select: none;

      &:focus-visible{
        outline: none;
        border-radius:360px;
        box-shadow: 0 0 0 1px var(--primary-color);
      }
    }
  }
}

.modern.optionPickerContainer {
  background-color: var(--grey-9-color);
  border-radius:8px;
  position: relative;
  
  .slider {
    position: absolute;
    background-color: var(--add-button-color);
    width: 46%;
    height: 75%;
    top: 50%;
    transform: translateY(-50%);
    border-radius:8px;
    left: 2%;
    transition: all 0.3s ease-in-out;
  }

  .optionsContainer{
    height: 100%;
    width: 100%;
    display: flex;

    .option{
      color: var(--secondary-color);
      z-index: 0;
      font-weight: 500;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: space-around;
      align-items: center;
      cursor: pointer;
      user-select: none;
      transition: color 0.3s;

      &:focus-visible{
        outline: none;
        border-radius: 8px;
        box-shadow: 0 0 0 1px var(--primary-color);
      }
    }

    .selected {
      color: white;
    }
  }
}
