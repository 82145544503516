@import 'variables.pcss';

.logs{
  position: relative;
  width: 100%;
  overflow: auto;
  height: 100%;
  display: flex;
  flex-direction: column-reverse;

  &-container{
    position: fixed;
    width: 100%;
    bottom: 0;
    left: 0;
    background: var(--secondary-color);
    color: white;
    font-size: 14px;
    max-height: 50vh;
    margin-bottom: -100%;
    height: 100%;
    transition: all .3s ease-in-out;
    z-index: 9999;
    padding: 8px;
    padding-right: 20px;
    box-sizing: border-box;
    opacity: 0;

    &-shown{
      margin-bottom: 0;
      opacity: 1;
    }
  
    &-minimized{
      max-height: 50px;
    }
  }
}

.log{
  display: grid;
  gap: 8px;
  grid-template-columns: 65px 1fr;
  white-space: break-spaces;

  &-info{
    color: var(--message-information-color);
  }

  &-warn{
    color: var(--message-warning-color);
  }

  &-err{
    color: var(--message-error-color);
  }
}

.toggleLogsButton {
  position: fixed;
  left: 10px;
  top: 10px;
  z-index: 9999;
  color: black;
  cursor: pointer;
  opacity: 1;

  svg{
    width: 18px;
    height: 18px;
  }
}

.minimizeButton{
  position: absolute;
  right: 1px;
  top: 4px;
  transition: transform .6s;
  cursor: pointer;

  svg{
    width: 18px;
    height: 18px;
  }
}

.maximizeButton{
  transform: rotate(180deg);
}

@media print{
  .logs{
    &-container{
      display: none;
    }
  }
}