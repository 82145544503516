.clients {
  display: flex;
  flex-grow: 1;
  min-height: 0;

  &-list-wrapper {
    display: flex;
    flex-direction: column;
    width: 32%;
    max-height: 100%;
    padding: 0 10px 10px;
  }

  &-action-button {
    height: 2em;
    margin: 0 5px;
    padding: 5px;
    align-self: flex-end;
  }

  &-select-button {
    margin-left: auto;
  }

  &-form {
    width: 68%;
  }

  &-submit-button {
    width: auto;
    padding: 0 10px;
  }

  &-infoWrapper {
    border: 1px black solid;
    width: 75%;
    margin: 0 auto;
    padding: 10px;
  }

  &-InfoButtons {
    display: flex;
    justify-content: space-between;
    padding-top: 10px;
  }
}

.hint {
  text-align: center;
  padding-bottom: 10px;
}
