@import 'variables.pcss';

.table {
  border-collapse: collapse;
  width: 100%;
  display: flex;
  flex-flow: column;
 
  &-header {
    @mixin tableheader-font;
    font-weight: 500;
    display: table;
  }

  &-body {
    @media (max-height: 1199px) {
      max-height: 62vh;
    }
    @media (min-height: 1200px) {
      max-height: 75vh;
    }
    overflow: auto;
    flex: 1;
    width: 100%;
  }

  &-light-row {
    background-color: var(--light-background-color);
    display: table;
    table-layout: fixed;
    width: 100%;
    height: 32px;
  }

  &-row {
    @mixin tabletext-font;
    height: 32px;
    background-color: var(--light-background-color);
    display: table;
    table-layout: fixed;
    width: 100%;
    cursor: pointer;

    &:nth-child(2n+1) {
      background-color: var(--dark-table-background-color);
    }

    &-notClickable{
      cursor: default;
    }

    &-removed {
      color: var(--disabled-font-color);
    }

    &-hoverLastElement td:last-child {
      visibility: hidden;
      * {
        transition: none !important;
      }
    }
    
    &-hoverLastElement:hover td:last-child{
      visibility: visible;
    }
  }

  &-element {
    border-bottom: 1px solid var(--border-color);
    padding: 0px 12px;
    overflow-wrap: break-word;
    word-break: break-word;
    hyphens: auto;
    &-phone {
      white-space: pre-wrap;
    }
  }

  &-actions {
    padding: 0;
  }

  &-pdf-actions {
    width: 65px;
    padding: 5px 20px 5px 10px;
    color: var(--primary-font-color);
  }

  &-right {
    text-align: right;
  }

  &-left {
    text-align: left;
  }

  &-sub-element {
    text-overflow: ellipsis;
    overflow: hidden;
    display: flex;
    width: 100%;
  }


  &-element-right {
    border-bottom: 1px solid var(--border-color);
    padding: 7.5px 30px 7.5px 15px;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &-narrow-column {
    width: 75px;
  }

  &-selector {
    @media (min-width:1251px) {
      width: 40px;
    }
    @media (min-width:901px) and (max-width:1250px) {
      width: 20px;
    }
    @media (max-width:900px) {
      width: 20px;
    }
    padding: 5px 20px 5px 10px;
    color: var(--primary-font-color);
  }

  &-icon {
    cursor: pointer;
    width: 20px;
    height: 20px;

    &-action {
      float: right;
      color: var(--action-button-color);
      padding: 5px 3px;
      margin: 3px;

      &:hover {
        color: #8c8c8c;
      }
    }

    &-disabled {
      float: right;
      color: var(--light-background-color);
      filter: brightness(0.90);
      padding: 5px 10px;
      border-radius: 2px;
      box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.24), 0px 0px 2px rgba(0, 0, 0, 0.12);
      margin: 3px;
      cursor: not-allowed;
    }
  }

  &-checkbox {
    display: flex;
    flex-direction: row;
    width: 100%;

    &-icon {
      cursor: pointer;
      width: 20px;
      height: 20px;
      z-index: 2;
      margin-right: 10px;

      &-action {
        float: right;
      }

      &:hover {
        color: #333;
      }
    }

    &-text {
      font-family: sans-serif;
      line-height: 20px;
    }
  }

  &-icons-element {
    width: 48px;
  }

  &-checkbox {
    width: 25px;
  }

  &-form-of-address {
    width: 55px;
  }

  &-date-of-birthday {
    width: 95px;
  }

  &-remove {
    width: 30px;
  }

  &-date-of-registration {
    width: 133px;
  }

  &-mileage {
    width: 80px;
  }

  &-number {
    width: 65px;
  }

  &-created-on {
    width: 75px;
  }

  &-status {
    width: 125px;
  }

  &-invoice-date {
    width: 75px;
  }

  &-due-date {
    width: 75px;
  }

  &-refers-to {
    width: 120px;
  }

  &-register-icons {
    width: 160px;
    padding: 0;
  }

  &-paid-on {
    width: 100px;
  }

  &-invoice-number {
    width: 150px;

    @media (max-width:1400px) {
      width: 100px;
    }
  }

  &-quick-margin {
    width: 100px;
  }

  &-amount {
    width: 150px;
  }
}

@media print {
  .table-selector,
  .table-actions,
  .table-remove,
  .table-actions,
  .table-icon,
  .table-icons-element,
  .table-register-icons {
    display: none;
  }

  .table-element{
    div span div{
      display: none;
    }
  }

  table {
   position: absolute;
   top: 50;
   page-break-after:auto;
   border-collapse: unset !important;
  }

  table thead{
    width: 100%;
    display: table !important;
  }

  table tbody tr    {
    page-break-inside:avoid; 
    page-break-after:auto;
    margin: 2px 0;
    display: inline-table !important;
    width: 100%;
  }

  table tbody   { display: table !important; width: 100%; }

  table tbody tr td    { page-break-inside:avoid; page-break-after:auto;}

  .table-element {
    padding: 0;
  }

  .table-body {
    max-height: none;
  }
}
