@import 'variables.pcss';

.switch {
  cursor: pointer;
  user-select: none;
  font: 500 13px Roboto,sans-serif;
  color: #636977;
  position: relative;

  &-focus-container{
    width: 100%;
    height: 100%;
    padding: 8px;
    position: absolute;
    top: -8px;
    left: -8px;
    border-radius: 8px;
    user-select: none;
    pointer-events: none;
  }

  &-focused{
    border: 1px solid var(--primary-color);
  }

  &-input {
    opacity: 0;
    width: 0;
    height: 0;
    user-select: none;
    position: absolute;
  }

  &-toggle {
    content: "";
    display: inline-block;
    width: 30px;
    height: 15px;
    border-radius: 15px;
    margin-right: 10px;
    vertical-align: middle;
    background-color: #E3E6E9;
    transition: background-color .3s ease;
    user-select: none;

    &:after {
      content: "";
      display: inline-block;
      width: 20px;
      height: 20px;
      background-color: #636977;
      border-radius: 20px;
      box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4);
      transform: translate(-5px, -2px);
      transition: transform .3s ease, background-color .3s ease;
    }

    &-checked {
      background-color: rgba(66, 148, 136, 0.2);
      &:after {
        transform: translate(15px, -2px);
        background-color: var(--primary-color);
      }
    }
  }
}
