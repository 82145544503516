@import 'variables.pcss';

.containerCard{
  padding: 16px;
  background: var(--grey-9-color);
  box-shadow: 1px 1px 5px rgba(79, 99, 116, 0.3);
  border-radius: 8px;
  overflow: hidden;
  cursor: pointer;
  border-radius: 8px;


  &-hover:hover{
    background-color: #E3EFED;
    cursor: pointer;
  }

  &-data{
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-self: center;
    height: 100%;
    width: 100%;

    &-player{
      height: 200px;
      width: 200px;
    }

    &-title{
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      color: var(--grey-2-color);
    }

    &-text{
      text-align: center;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      color: var(--grey-2-color);
    }

    &-icon{
      color: var(--grey-2-color);
      text-align: center;
    }
  }
}
