@import "variables.pcss";

.header{
  margin: 5px 5px 7px 5px;
  justify-content: space-between;
  display: flex;
    &-text{
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
      color: var(--secondary-color);
      margin-right: 10px;
      margin-top: auto;
      margin-bottom: auto;
    }
  
    &-icon{
      color: var(--secondary-color);
      font-size: 20px;
      margin-right: 10px;
      margin-top: auto;
      margin-bottom: auto;
 
       &-vehicle{
         font-size: 18px;
         vertical-align: bottom;
       }
    }
  
    &-add{
      color: var(--secondary-color);
      font-size: 20px;
      float: right;
      cursor: pointer;
   }
 
   &-action{
     margin-left: auto;
     position: relative;
   }
 
   &-cardContainer{
     width: 100% !important;
   }
 }

.selectButtonContainer{
  display: flex;
  position: relative;
  margin-left: auto;
}

.listGrid{
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));

  &-hidden{
    display: none;
  }
}

.listGrid > :last-child {
  margin: 0;
}

.list{
  display: flex;
  flex-flow: column;
  gap: var(--spacing-s);
  margin-right: var(--spacing-m);
}

.genericTableSearch{
  max-width: 250px;
  margin-bottom: var(--spacing-m);

  &-mobile{
    max-width: none;
  }
}

.addActionsMobile{
  margin: var(--spacing-m) 0;
}

.addActionsMobile > button {
  width: 100%;
}

.info {
  padding-bottom: 6px;
}