@import "variables.pcss";

.form {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;

    &-item {
        width: 200px;
        height: 75px;
    }

    &-header{
        padding: 10px 0 20px 0;
    }

    &-info{
        color: var(--grey-6-color);
        font-size: 12px;
        font-weight: 400;
        padding-bottom: 30px;
    }
}

.selectorContainer{
    margin-top: 10px;
    &-element{
        display: flex;
    }
    &-numberSelector{
        display: inline-flex;
    }

    &-number{
        display: block;
        gap: 20px;
        margin-left: 20px;
        &-preview{
            font-weight: 500;
            font-size: 14px;
    
            &-number{
                font-size: 14px;
                white-space: nowrap;
            }
        }
    }
    
    &-buttons{
        display: flex;
        gap: 30px;
    }
}
