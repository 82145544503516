@import 'variables.pcss';

.fabNavigation {

  * {
    z-index: 2;
  }

  --animation-duration: 0.4s;

  --animation-delay: 0.05s;

  @define-mixin fab {
    background-color: var(--primary-color);
    border-radius: 100%;
    aspect-ratio: 1;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.2);
  }

  &-selectionBackground{
    background-color: var(--grey-3-color);
    opacity: 0;
    position: fixed;
    width: 100%;
    height: 100%;
    transition: all 0.4s;
    user-select: none;
    display: none;
    pointer-events: none;

    &-visible{
      opacity: 0.4;
      display: unset;
      user-select: auto;
      pointer-events: auto;
    }
  }

  &-fab{
    @mixin fab;
    position: fixed;
    width: 56px !important;
    background-color: var(--primary-color);
    font-size: 50px;
    color: white;

    &-content{
      position: absolute;
      bottom: 68px;
      right: 0px;
      display: flex;
      flex-flow: column-reverse;
      gap: 12px;

      &-entry{
        display: flex;
        flex-direction: row-reverse;
        color: var(--secondary-color);
        font-size: 16px;
        opacity: 0;
        animation-name: appear;
        animation-duration: 0.2s;
        animation-fill-mode: forwards;
        text-decoration: none;


        &:nth-child(2) {
          animation-delay: var(--animation-delay);
        }
        &:nth-child(3) {
          animation-delay: calc(var(--animation-delay) * 2);
        }
        &:nth-child(4) {
          animation-delay: calc(var(--animation-delay) * 3);
        }
        &:nth-child(5) {
          animation-delay: calc(var(--animation-delay) * 4);
        }
      }

      &-label{
        white-space: nowrap;
        display: flex;
        align-items: center;
        background-color: white;
        padding: 0 12px;
        border-radius: 8px;
        margin: 5px 0;
      }

      &-fab {
        @mixin fab;
        width: 40px !important;
        height: 40px;
        font-size: 20px;
        color: var(--secondary-color);
        background-color: var(--grey-9-color);

        &-container {
          width: 56px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }


  &-menu{
    bottom: 16px;
    left: 16px;
    font-size: 20px;
    color: var(--secondary-color);
    background-color: #F6F6FA;
  }

  &-quickActions{
    bottom: 16px;
    right: 16px;

    &-active{
      transform: rotateZ(0deg);
      animation: turn var(--animation-duration) forwards;
    }

    &-inactive{
      transform: rotateZ(45deg);
      animation: turnBack var(--animation-duration) forwards; /*0.6s;*/
    }
  }
}

@keyframes turn{
  0% {
      transform: rotateZ(0deg);
  }
  100% {
      transform: rotateZ(45deg);
  }
}

@keyframes turnBack{
  0% {
      transform: rotateZ(45deg);
  }
  70%{
      transform: rotateZ(-15deg);
  }
  100% {
      transform: rotateZ(0deg);
  }
}

@keyframes appear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
