@import 'variables.pcss';

.page {
  margin: 20px;

  @media (--breakpoint-mobile) {
    margin: 20px 0;
  }

  &-title {
    @mixin title-font;
    padding: 15px 10px;
    background-color: var(--light-background-color);
  }

  &-sub-title {
    @mixin sub-title-font;
    padding: 15px 10px;
    background-color: var(--light-background-color);
  }

  &-content {
    background-color: var(--light-background-color);
    box-shadow: 0 0 4px rgba(0,0,0,.14), 0 4px 8px rgba(0, 0, 0, .28);
    position: relative;

    &-form {
      padding: 15px;
    }
  }

  &-actions {
    padding: 16px;
    display: flex;
    justify-content: space-between;
    height: 36px;

    &-selectMonth {
      display: flex;
      justify-content: space-between;
      background-color: var(--message-information-color);
      box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.24), 0px 0px 2px rgba(0, 0, 0, 0.12);
      color: var(--white-color);
      text-transform: uppercase;
      text-align: center;
      border-radius: 2px;

      &-title{
        padding: 5px 10px;
        min-width: 95px;
      }

      button{
        box-shadow: none;
      }
    }

    &-buttons {
      display: flex;
      flex-direction: row;

      button {
        margin-left: 5px;
      }
    }

    &-balance {
      display: flex;
      justify-content: flex-end;
      padding: 15px 13.5%;

        &-text {
          @mixin tableheader-font;
          padding: 0 10px;
        }
    }

    &-search {
      display: flex;

      &-result {
        @mixin text-font;
        padding: 12px 0 12px 12px;
      }
    }

    &-hidden{
      display: none;
    }
  }

  &-footer {
    padding: 10px;
    display: flex;
  }

  &-data {
    display: flex;
    flex-direction: column;
    background-color: var(--light-background-color);

    &-block {
      display: flex;
      flex-wrap: wrap;
    }

    &-element {
      display: flex;
      flex-direction: column;
      margin: 24px;

      &-row {
        display: flex;
        flex-direction: row;
      }

      &-title {
        margin-bottom: 5px;
      }
    }
  }

  &-link {
    @mixin link-font;
  }

  &-multilineText {
    white-space: pre-wrap;
  }

  &-with-vin-wrapper {
    align-items: center;
    display: flex;
    flex-direction: row;
  }

  &-vin-counter {
    margin-left: 6px;
  }

  &-icon-spin {
    -webkit-animation: icon-spin 2s infinite linear;
    animation: icon-spin 2s infinite linear;
  }

  @keyframes icon-spin {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(359deg);
      transform: rotate(359deg);
    }
  }

  @keyframes icon-spin {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(359deg);
      transform: rotate(359deg);
    }
  }
}

@media print {
  .page {
    margin: 0;

    &-actions {
      button {
        display: none;
      }
    }

    &-actions-search {
      display: none;
    }

    &-actions {
      margin: 0;
      padding: 0;    
    }

    &-content {
      box-shadow: none;
    }
  }
}
