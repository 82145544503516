@import 'variables.pcss';

.date-picker {
    color: #6F6F6F;
    position: relative;

    &-container{
        width: 100%;
    }

    &-container > div:first-child > div:first-child {
        width: 100%;
    }

    &-container > div:first-child > div:first-child > div:first-child {
        width: 100%;
    }

    &-label {
        position: relative;
        display: flex;
        @mixin text-font;

        &-column {
            flex-direction: column;
        }

        &-text {
            white-space: nowrap;
            margin-right: 5px;
            margin-bottom: 5px;
            display: flex;
            align-items: center;
            color: #6F6F6F;
            background-color: var(--light-background-color);
            font-size: 12px;
            position: absolute;
            top: -15px;
        }
    }

    &-field {
        @mixin text-font;
        border: 1px solid rgba(0, 0, 0, 0.12);
        border-radius: 8px;
        box-sizing: border-box;
        padding: 9px;
        width: 100%;
        line-height: 20px;

        &:focus {
            outline: none;
            border-color: var(--primary-color);
        }

        &-error {
            border: 1px solid var(--error-border-color);

            &:focus {
                border-color: var(--error-border-color);
            }
        }
    }

    &-icon {
        float: right;
        background-color: transparent;
        border: none;
        position: absolute;
        outline: none;
        right: 8px;
        top: 13px;
        color: #A3A9B9;
        pointer-events: none;
        width: 1.2em;
        height: 1.2em;
    }

    &-required-mark {
        padding: 0px 0px 0px 4px;
        color: var(--error-color);
    }

    &-error {
        @mixin error-font;
        margin-top: 4px;
    }
}
