@import "variables.pcss";

.maintenance {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  &-container{
    position: relative;
    width: min(640px, 90%);
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    &-heading {
      font-weight: 500;
      font-size: 24px;
      color: var(--secondary-color);
    }

    &-text{
      margin-top: 42px;
      font-weight: 400;
      font-size: 16px;
    }
  }
}