.logo{
  display: flex;
  align-items: center;
  gap: 4px;

  &-svg{
    width: 1.3em;
  }

  &-text{
    font-family: 'Ubuntu';
    font-weight: 700;

    &-main{
      color: var(--primary-color);
      fill: var(--primary-color);
    }

    &-secondary{
      color: var(--secondary-color);
      fill: var(--secondary-color);
    }
  }
}
