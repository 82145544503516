@import 'variables.pcss';

.vehicleForm{
  display: grid;
  grid-auto-flow: row;
  grid-gap: 32px 64px;
  grid-template-columns: 100%; /* Cant use 1fr, since the word overflow in vehicleClass will break */
  &-row{
    display: flex;
    flex-flow: row;
    gap: 32px;

    &-block{
      display: flex;
      flex-flow: column;
      gap: 32px;
    }
  
    & > * {
      width: 100%;
    }
  }

  &-customer{
    margin-bottom: 32px;
  }

  &-buttons{
    margin-top: 48px;
  }
}


@media not (--breakpoint-tablet) {
  .vehicleForm{
    grid-auto-flow: column;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-template-rows: repeat(10, 40px);
    &-row{
  
      &-block{
        grid-row: span 2;
      }
    }

    &-dates{
      justify-content: center;
      grid-row: span 3;
    }

    &-additionalInfo{
      grid-row: 9 / span 2;
      grid-column: 1 /span 2;
    }
  }
}

.vin{
  &-wrapper{
    position: relative;
  }
  &-counter{
    position: absolute;
    right: 13px;
    top: 50%;
    transform: translateY(-50%);

    &-green{
      color: green;
      font-weight: bold;
    }
  }
}
