@import 'variables.pcss';

.buttonModernLoadingContainer {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
}

.buttonModern{
  @mixin button-font-modern;
  display: inline-block;
  padding: 13px 19px;
  color: white;
  border: none;
  border-radius: 8px;
  transition: all .3s;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  white-space: pre;
  justify-content: center;
  position: relative;

  &-loading{
    &:not(.buttonModernLoadingContainer) {
      color: transparent;
      * > {
        visibility: hidden;
      }
    }
  }

  &-content{
    position: relative;
    white-space: break-spaces;

    div {
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  &-small{
    padding: 8px 12px;
    font-weight: 400;
  }

  &:hover{
    box-shadow: 0 0 8px rgba(0,0,0,0.75);
  }

  &:focus-visible{
    outline: none;
    box-shadow: 0 0 8px rgba(0,0,0,0.75);
  }

  &-add {
    background-color: var(--primary-color);
    color:white;

    &[disabled] {
      background-color: var(--add-button-disabled-color);
      cursor: not-allowed;
    }
  }

  &-export {
    background-color: var(--export-button-color);

    &:hover {
      filter: brightness(1.15);
    }

    &[disabled] {
      filter: brightness(1.08);
      cursor: not-allowed;

      &:hover {
        filter: brightness(1.12);
      }
    }
  }

  &-cancel {
    background-color: var(--grey-9-color);
    color: var(--secondary-color);
    min-width: 110px;

    &:hover {
      box-shadow: 0 0 8px rgba(0,0,0,0.75);
    }

    &[disabled] {
      filter: brightness(0.8);
      cursor: not-allowed;
    }
  }

  &-highlight {
    background-color: var(--highlight-color);
    color: var(--secondary-color);
    min-width: 110px;

    &:hover {
      box-shadow: 0 0 8px rgba(0,0,0,0.75);
    }

    &[disabled] {
      filter: brightness(0.8);
      cursor: not-allowed;
    }
  }

  &-info {
    background-color: var(--message-information-color);
    color: var(--white-color);
    min-width: 110px;

    &:hover {
      box-shadow: 0 0 8px rgba(0,0,0,0.75);
    }

    &[disabled] {
      filter: brightness(0.8);
      cursor: not-allowed;
    }
  }

  &-delete {
    color: var(--message-error-color);
    background-color: var(--message-error-light-color);

    &[disabled] {
      filter: brightness(0.8);
      cursor: not-allowed;

      &:hover {
        filter: brightness(0.7);
      }
    }
  }

  &-select{
    color: var(--secondary-color);
    background-color: var(--grey-9-color);
    font-weight: 400;
    padding: 10px 15px;
    display: flex;
    gap: 8px;
    align-items: center;
    max-width: 100%;
    justify-content: center;

    &:hover {
      box-shadow: none;
      filter: brightness(0.92);
    }

    &[disabled] {
      filter: brightness(0.96);
      cursor: not-allowed;

      &:hover {
        filter: brightness(0.96);
      }
    }
  }

  &-config {
    background-color: white;
    color: #3e5a6c;

    &[disabled] {
      background-color: var(--add-button-disabled-color);
      cursor: not-allowed;
    }
  }

  &-size{
    &-small{
      font-size: 14px;
      line-height: 16px;
      padding: 6px 8px;
      min-height: 16px;
      font-weight: 400;
    }
    &-medium{
      font-weight: 400;
      font-size: 16px;
      padding: 8px 12px;
      min-height: 19px;
    }
    &-default{
      font-size: 16px;
    }
    &-medium{
      padding: 8px 12px;
      font-size: 16px;
      font-weight: 400;
    }
  }

  &-withIcon {
    display: flex;
    gap: 8px;
  }
}