@import 'variables.pcss';

.isRequired {
  color: var(--message-error-color);
}

.number-input {
    position: relative;

    &-label {
        display: flex;
        flex-direction: row;

        &-column {
            flex-direction: column;
        }

        &-text {
            @mixin text-font;
            white-space: nowrap;
            margin-right: 5px;
            margin-bottom: 6px;
            display: flex;
            align-items: center;
            background-color: transparent;
            font-size: 12px;
            position: absolute;
            top: -15px;
        }
    }

    &-unit{
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
    }

    &-field {

        &-input {
            border: 1px solid #E0E0E0;
            border-radius: 8px;
            padding: 7.5px 29px 7.5px 7.5px;
            width: calc(100% - 40px);
            font-size: 14px;
            line-height: 20px;
            text-align: left;

            &-icon {
                border: 1px solid #E0E0E0;
                border-radius: 8px;
                padding: 9px 29px 9px 19px;
                width: calc(100% - 40px);
                font-size: 14px;
                line-height: 20px;
                text-align: left;

                &:focus-visible {
                    border: 1px solid var(--primary-color);
                    outline: none;
                }
            }

            &:focus-visible {
                border: 1px solid var(--primary-color);
                outline: none;
            }
        }

        &-withHoverIcon{
            width: calc(100% + 25px) !important;
            position: relative;
            padding-right: 25px !important;
        }

        &-withHoverIconMedium{
            width: calc(100% - 7.5px) !important;
            position: relative;
            padding-right: 25px !important;
        }

        &-withHoverIconAnim{
            transition: all 0.5s ease-out;
            transform: scale(1);
            width: calc(100% + 25px) !important;
            position: relative;
            padding-right: 25px !important;
        }

        &-withHoverIconAnimMedium{
            transition: all 0.5s ease-out;
            transform: scale(1);
            width: calc(100% - 7.5px) !important;
            position: relative;
            padding-right: 25px !important;
        }

        &-error{
            border: 1px solid var(--error-color);

            &:focus-visible {
                border: 1px solid var(--error-color);
                outline: none;
            }
        }
    }
    &-arrows {
        position: absolute;
        left: calc(100% - 20px);
        top: 4px;
        display: flex;
        flex-direction: column;
    }

    &-arrow-button {
        background-color: unset;
        border: none;
        padding: 0;
        color: #A3A9B9;
    }

    &-up-arrow {
        width: 0;
        height: 0;
        border-left: 4px solid transparent;
        border-right: 4px solid transparent;
        border-bottom: 5px solid rgba(0, 0, 0, 0.52);
    }

    &-down-arrow {
        width: 0;
        height: 0;
        border-left: 4px solid transparent;
        border-right: 4px solid transparent;
        border-top: 5px solid rgba(0, 0, 0, 0.52);
    }

    &-icon{
        position: absolute;
        right: calc(100% - 20px);
        top: 14px;
        font-size: 12px;
        display: flex;
        padding-right: 2px;
        flex-direction: column;
        color: #A3A9B9;
    }

    &-hoverIcon{
        position: absolute;
        left: calc(100% + 5px);
        top: 3px;
        display: flex;
        padding-right: 2px;
        flex-direction: column;
        color: #A3A9B9;
        font-size: 20px;
        z-index: 1;
        cursor: pointer;
        &-medium{
            top: 6px;
        }
        &-toolTip{
            background-color: #454953 !important;
            border-radius: 3px;
            margin: 5px !important;
        }
    }
    &-hoverIcon:hover{
        cursor: pointer;
    }
    &-hoverIcon:focus{
        cursor: pointer;
    }

    &-selected{
        z-index: 3;
    }
}

@keyframes fade-in {
    0% {
      transform: translateX(-10px);
      opacity: 0;
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
}
  
@keyframes fade-out {
    0% {
      transform: translateX(0);
      opacity: 1;
    }
    100% {
      transform: translateX(-10px);
      opacity: 0;
    }
}
  
  
  
.fade-in {
    animation: fade-in 0.5s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}
  
.fade-out {
    animation: fade-out 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}