.deleteIcon {
    width: 1.2em;
    height: 1.2em;
    vertical-align: middle;
    padding-bottom: 3px;
    padding-right: 12px;
}

.deleteAction {
    justify-content: center;
}