.signin{
    &-signupLink{
        font-size: 16px;
    }
    
    &-passwordResetLink{
        font-size: 10px;
    }
}

